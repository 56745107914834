import React, { useState, useEffect } from 'react';
import api from 'state/api';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "react-js-loader";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as XLSX from 'xlsx';
import EditPromoCodeDialog from './editDialog';
import AddPromoCodeDialog from './addDialog';
import Pagination from 'components/pagination';
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import  { Input } from '@trendmicro/react-form-control';

const PromoCodes = () => {
    // State variables
    const [promoCodes, setPromoCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [promoCodesPerPage] = useState(100);

    /// filters
    const [selectedPromoCodeId, setSelectedPromoCodeId] = useState('');
    const [searchCode, setSearchCode] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [selectedWorking, setSelectedWorking] = useState(null);
    const [selectedUsesLeftRange, setSelectedUsesLeftRange] = useState('');


    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    // Single Delete
    const [deletingPromoCodeId, setDeletingPromoCodeId] = useState('');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // Multi Delete
    const [deletingPromoCodeIds, setDeletingPromoCodeIds] = useState([]);
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    // Edit promo code data Dialog
    const [selectedPromoCodeData, setSelectedPromoCodeData] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    // Add new promo code Dialog
    const [addNewPromoCodeDialogOpen, setAddNewPromoCodeDialogOpen] = useState(false);

    useEffect(() => {
        const fetchPromoCodes = async () => {
        try {
            setLoading(true);
            const response = await api.getPromoCodes();
            setPromoCodes(response.promoCode);
            setSelectAll(false);
        } catch (error) {
            console.error('Error fetching Promo Codes:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchPromoCodes();
    }, [currentPage]);

    const isNumberInSelectedRange = (number) => {
        if (!selectedUsesLeftRange) {
            return true; // If no range selected, include all numbers
        }
        if (selectedUsesLeftRange.includes('+')) {
            const minNumber = parseInt(selectedUsesLeftRange);
            return number >= minNumber;
        } else {
            const [min, max] = selectedUsesLeftRange.split('-').map(Number);
            return number >= min && (max === 0 || number <= max);
        }
    };
    
    

  // Select all + Pagination
    const filteredPromoCodes = promoCodes.filter((promoCode) => {
        const promoCodeFilter = selectedPromoCodeId ? promoCode.id === selectedPromoCodeId : true;
        const codeFilter = !searchCode || promoCode.code.toLowerCase().includes(searchCode.toLowerCase());
        const valueFilter = !searchValue || promoCode.value.toString().includes(searchValue);
        const WorkingFilter = !selectedWorking || promoCode.is_working.includes(selectedWorking);
        const usesLeftFilter = isNumberInSelectedRange(promoCode.max_number_of_used);

        return (
            promoCodeFilter && 
            codeFilter &&
            valueFilter &&
            WorkingFilter &&
            usesLeftFilter
        );
    });
  // Calculate the range of Promo Code to display based on pagination
    const indexOfLastPromoCode = currentPage * promoCodesPerPage;
    const indexOfFirstPromoCode = indexOfLastPromoCode - promoCodesPerPage;
    const currentPromoCodes = filteredPromoCodes.slice(indexOfFirstPromoCode, indexOfLastPromoCode);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredPromoCodes.length / promoCodesPerPage); i++) {
        pageNumbers.push(i);
    }
  // Handle checkbox change for individual Promo Code
    const handleCheckboxChange = (promoCoded) => {
        const updatedpromoCodes = promoCodes.map((promoCode) =>
        promoCode.id === promoCoded ? { ...promoCode, selected: !promoCode.selected } : promoCode
        );
        setPromoCodes(updatedpromoCodes);
    };

  // Handle checkbox change for all Promo Code
    const handleSelectAll = () => {
        const updatedpromoCodes = promoCodes.map((promoCode) =>
        currentPromoCodes.some((currentPromoCode) => currentPromoCode.id === promoCode.id)
            ? { ...promoCode, selected: !selectAll }
            : promoCode
        );
        setPromoCodes(updatedpromoCodes);
        setSelectAll(!selectAll);
    };    

    // Format updated and created at data
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Africa/Cairo',
        };
            return new Date(dateString).toLocaleDateString('en-US', options);
        };
    /// Filters
    const handlePromoCodeIdChange = (e) => {
        setSelectedPromoCodeId(e.target.value);
        setCurrentPage(1);
    };
    const handleCodeChange = (e) => {
        setSearchCode(e.target.value);
        setCurrentPage(1);
    };

    const handleValueChange = (e) => {
        setSearchValue(e.target.value);
        setCurrentPage(1);
    };
    const handleWorkingChange = (e) => {
        setSelectedWorking(e.target.value);
        setCurrentPage(1);
    };
    const handleUsesLeftRangeChange = (e) => {
        setSelectedUsesLeftRange(e.target.value);
        setCurrentPage(1);
    };
     //////////////////////////////////////////////////////////////////////////////////////////////////
    // Alert
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setAlert({ ...alert, open: false });
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle single delete  
    const handleDeleteSingle = (promoCodeId) => {
        setDeletingPromoCodeId(promoCodeId);
        setDeleteDialogOpen(true);
        };
    const handleDeleteConfirmationSingle = async () => {
        try {
            setLoading(true);
            setDeleteDialogOpen(false);
            await api.removePromoCode(deletingPromoCodeId);
            const response = await api.getPromoCodes();
            setPromoCodes(response.promoCode);
            setAlert({
            open: true,
            message: 'Promo Code deleted successfully!',
            severity: 'success',
            });
        } catch (error) {
            console.error("Error deleting promo code:", error);
            setAlert({
            open: true,
            message: 'Failed to delete promo code. Please try again.',
            severity: 'error',
            });
        } finally {
            setDeletingPromoCodeId(null);
            setLoading(false);
        }
        };
    const handleDeleteCancelSingle = () => {
        setDeleteDialogOpen(false);
        setDeletingPromoCodeId(null);
        };
//////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle delete from select
    const handleDelete = () => {
        const selectedPromoCodesIds = promoCodes.filter((promoCode) => promoCode.selected).map((promoCode) => promoCode.id);
        if (selectedPromoCodesIds.length === 0) {
        console.log('No Promo Codes selected for deletion');
        return;
        }
        setDeletingPromoCodeIds(selectedPromoCodesIds);
        setDeleteConfirmationDialogOpen(true);
    };
    const handleDeleteConfirmation = async () => {
        try {
        setDeleteConfirmationDialogOpen(false);
        setLoading(true);
        await api.removePromoCodes({ promoCodesIds: deletingPromoCodeIds });
        const response = await api.getPromoCodes();
        setPromoCodes(response.promoCode);
            setAlert({
            open: true,
            message: 'Promo codes deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting promo codes:", error);
        setAlert({
            open: true,
            message: 'Failed to delete promo codes. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingPromoCodeIds(null);
        setLoading(false);
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmationDialogOpen(false);
    };
      //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle reset filters / Export
    const handleResetFilters = () => {
        setSelectedPromoCodeId('');
        setSearchCode('');
        setSearchValue('');
        setSelectedWorking('');
        setSelectedUsesLeftRange('');
        setCurrentPage(1); 
    };
    const handleExport = () => {
        const selectedPromoCodes = promoCodes.filter((promoCode) => promoCode.selected);
        if (selectedPromoCodes.length === 0) {
            console.log('No selected Promo Codes to export');
            return;
        }
    
        // Prepare data for export
        const dataForExport = selectedPromoCodes.map((promoCode) => ({
        'Promo Code ID': promoCode.id,
        'Code': promoCode.code,
        'Value': promoCode.value,
        'Is Working?': promoCode.is_working,
        'Amount deducted': promoCode.max_amount,
        'Number of uses left': promoCode.max_number_of_used,
        'Expiry date': promoCode.expiry_date,
        'Created At': formatDate(promoCode.created_at),
        'Updated At': formatDate(promoCode.updated_at),
        }));
        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Promocodes');
        XLSX.writeFile(wb, 'promocodes.xlsx');
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // Edit existing promocode
    const handleEditClick = (promocode) => {
        setSelectedPromoCodeData(promocode);
        setEditDialogOpen(true);
    };
    const handleEditSubmit = async (editedData) => {
        try {
        setLoading(true);
    
        const response = await api.updatePromoCode(editedData);
        console.log('API response:', response);
    
        const Response = await api.getPromoCodes();
        setPromoCodes(Response.promoCode);
    
        setAlert({
            open: true,
            message: 'Promo Code updated successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error('Error submitting edited data:', error);
        setAlert({
            open: true,
            message: 'Failed to update promo code. Please try again.',
            severity: 'error',
        });
        } finally {
        setSelectedPromoCodeData(null);
        setLoading(false);
        }
    };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// Add new Promo code
    const handleAddPromocodeClick = () => {
        setAddNewPromoCodeDialogOpen(true);
    };

    const handleAddNPromoCodeSubmit = async (data) => {
        try {
            setLoading(true);
            await api.addPromoCode(data);
            const response = await api.getPromoCodes();
            setPromoCodes(response.promoCode);

            setAlert({
                open: true,
                message: 'Promo Code Added successfully!',
                severity: 'success',
            });

            setAddNewPromoCodeDialogOpen(false);
        } catch (error) {
            console.error('Error submitting adding new Promo code:', error);
            setAlert({
                open: true,
                message: 'Failed to update Promo code. Please try again.',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };    
    return (
        <div className="container">
            <div className="filter-section">
                <div className="filter-set">
                    <FormControl className="filter-form-control">
                    <InputLabel>Promo Code Id</InputLabel>
                    <Select value={selectedPromoCodeId || ''} onChange={handlePromoCodeIdChange}>
                        <MenuItem value="" disabled>Select Promo Code ID</MenuItem>
                        {promoCodes.map((promocode) => (
                        <MenuItem key={promocode.id} value={promocode.id}>
                            {promocode.id}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>      

                    <FormControl className="filter-form-control">
                        <Input
                            value={searchCode}
                            onChange={handleCodeChange}
                            placeholder ="Promo Code"
                        />
                    </FormControl>  

                    <FormControl className="filter-form-control">
                        <Input
                            value={searchValue}
                            onChange={handleValueChange}
                            placeholder ="Value of promo code"
                        />
                    </FormControl>    

                    <FormControl className="filter-form-control" >
                    <InputLabel>Status of promo code</InputLabel>
                    <Select value={selectedWorking || ''}  onChange={handleWorkingChange}>
                        <MenuItem disabled> Select Status</MenuItem>
                        <MenuItem value="Working">Working</MenuItem>
                        <MenuItem value="Expired">Expired</MenuItem>
                    </Select>
                    </FormControl>   
                    <FormControl className="filter-form-control">
                        <InputLabel>No of uses left range</InputLabel>
                        <Select value={selectedUsesLeftRange || ''} onChange={handleUsesLeftRangeChange}>
                            <MenuItem disabled>Select Range</MenuItem>
                            <MenuItem value="0-200">0 - 200</MenuItem>
                            <MenuItem value="201-1000">200 - 1000</MenuItem>
                            <MenuItem value="1001+">1001 and above</MenuItem>
                        </Select>
                    </FormControl>        
                </div>
            </div>

            <div className="actions-section">
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddPromocodeClick()}

                >
                    <AddIcon /> Add New Promo code
                </Button>
                </Box>
                <div className="actions-container">
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                    >
                        <DeleteIcon /> Delete
                    </Button>
                    </Box>

                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleExport}
                    >
                        <SaveAltIcon /> Export
                    </Button>
                    </Box>
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleResetFilters}
                    >
                        <RefreshIcon /> Reset filters
                    </Button>
                    </Box>
                </div>  
            </div>
            {loading ? (
            <div className="item">
                <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
            </div>
            ) : (
            <table className="lookup-table">
                <thead>
                    <tr>
                    <th>
                        <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectAll}
                        />
                    </th>
                    <th>Promo Code ID</th>
                    <th>Code</th>
                    <th>Value</th>
                    <th>Working</th>
                    <th>Amount deducted</th>
                    <th>Number of uses left</th>
                    <th>Expiry date</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>  
                    </tr>
                </thead>
                <tbody>
                    {filteredPromoCodes.map((promoCode) => (
                    <tr key={promoCode.id}>
                        <td>
                        <input type="checkbox"
                        checked={promoCode.selected || false}
                        onChange={() => handleCheckboxChange(promoCode.id)} 
                        />
                        </td>
                        <td>{promoCode.id}</td>
                        <td>{promoCode.code}</td>
                        <td>{promoCode.value}</td>
                        <td>{promoCode.is_working}</td>
                        <td>{promoCode.max_amount}</td>
                        <td>{promoCode.max_number_of_used}</td>
                        <td>{promoCode.expiry_date}</td>
                        <td>{formatDate(promoCode.created_at)}</td>
                        <td>{formatDate(promoCode.updated_at)}</td>
                        <td> <IconButton size="small" onClick={() => handleEditClick(promoCode)} ><EditIcon /></IconButton>
                            <IconButton size="small" onClick={() => handleDeleteSingle(promoCode.id)} ><DeleteIcon /></IconButton>
                    </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            )}
            <Pagination
                currentPage={currentPage}
                paginate={paginate}
                totalItems={filteredPromoCodes.length}
                itemsPerPage={promoCodesPerPage}
                prevButtonLabel="< Previous"
                nextButtonLabel="Next >"
                buttonColor="primary"
                activeButtonColor="contained"
                inactiveButtonColor="default"
                disabled={false}
            />
            <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
                <DialogContent className="dialog-content">
                    <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this promo code?</Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button onClick={handleDeleteCancelSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                    Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmationSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isDeleteConfirmationDialogOpen} onClose={handleDeleteCancel}>
                <DialogContent className="dialog-content">
                    <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete the selected promo codes?</Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button onClick={handleDeleteCancel} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                    Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={alert.open} autoHideDuration={1000} onClose={handleCloseAlert}>
                <MuiAlert
                onClose={handleCloseAlert}
                severity={alert.severity}
                sx={{ width: '100%', fontSize: '1.5rem' }}
                >
                {alert.message}
                </MuiAlert>
            </Snackbar>

            <EditPromoCodeDialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)} promoCodeData={selectedPromoCodeData} onSubmit={handleEditSubmit}/>
            <AddPromoCodeDialog isOpen={addNewPromoCodeDialogOpen} onClose={() => setAddNewPromoCodeDialogOpen(false)} onSubmit={handleAddNPromoCodeSubmit}/>


        </div>
    );
};

export default PromoCodes;
