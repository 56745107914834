import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AWS from "aws-sdk";
import Snackbar from "@mui/material/Snackbar";

const EditBlogDialog = ({ isOpen, onClose, blogData, onSubmit  }) => {
  

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const [enlargedImage, setEnlargedImage] = useState(null);

  const handleThumbnailDoubleClick = (imageUrl) => {
    setEnlargedImage(imageUrl);
  };
  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };



      const openSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
      };

      const closeSnackbar = () => {
        setSnackbarOpen(false);
      };
      
   //////Product Img
  function selectFiles() {
    fileInputRef.current.click();
  }
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    // Filter out only image files (you can customize the accepted file types)
    const imageFiles = Array.from(selectedFiles).filter((file) =>
      file.type.startsWith("image/")
    );

    // Map the selected files to an array of image objects
    const newImages = Array.from(imageFiles).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      key: Date.now(),
    }));

    // Update both files and images state
    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  function deleteImageProductImg2(media, index) {
    // Display a confirmation pop-up
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this photo?"
    );

    if (!isConfirmed) {
      return;
    }

    fileInputRef.current.value = null;

    // Get the url of the media to be deleted
    const urlToDelete = media.image_url;
    // Update the images state
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));

    // Update the product_media state
    setEditedData((prevData) => {
      const updatedMedia = prevData.image_url
        .filter((media, i) => i !== index)
        .map((media, i) => ({ ...media, index: i }));

      // Delete the S3 object
      const s3 = new AWS.S3({
        accessKeyId: "AKIARBDTOX44C3KS5CGY",
        secretAccessKey: "4WNLIkVyJcQylefk8AODebxR/hZujMGa4aICZlAb",
        region: "me-south-1", // Replace with your AWS region
      });

      const keyToDelete = urlToDelete.split("/").pop(); // Extract the object key from the URL
      const deleteParams = {
        Bucket: "natinda", // Replace with your S3 bucket name
        Key: `ProductsImages/${keyToDelete}`,
      };

      s3.deleteObject(deleteParams, (err, data) => {
        if (err) {
          console.error("Error deleting S3 object:", err);
        } else {
          console.log("S3 object deleted successfully:", data);
        }
      });

      // Make an HTTP request to delete the media from the backend
      fetch(`https://dashboard.natindamiddleeast.com/api/deleteMedia`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_url: urlToDelete,
        }),
      })
        .then((response) => {
          if (response.ok) {
            // Success
            console.log("Media deleted successfully.");
          } else {
            // Handle error
            console.error("Failed to delete media.");
          }
        })
        .catch((error) => {
          console.error("Error deleting media:", error);
        });

      return {
        ...prevData,
        product_media: updatedMedia,
      };
    });
  }
  function deleteImageProductImg(index) {
    fileInputRef.current.value = null;
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFiles((prevImages) => prevImages.filter((_, i) => i !== index));
  }
  function onDragOverProductImg(event) {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  }
  function onDragLeaveProductImg(event) {
    event.preventDefault();
    setIsDragging(false);
  }
  function onDrop(event) {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = event.dataTransfer.files;

    // Filter out only image files (you can customize the accepted file types)
    const imageFiles = Array.from(droppedFiles).filter((file) =>
      file.type.startsWith("image/")
    );

    // Map the dropped files to an array of image objects
    const newImages = Array.from(imageFiles).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      key: Date.now(),
    }));

    // Update both files and images state
    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    setImages((prevImages) => [...prevImages, ...newImages]);
  }


    const [editedData, setEditedData] = useState({
    id: '',
    title_en: '',
    title_ar: '',
    content_en: '',
    content_ar: '',
    media: [],
    hidden: '',
    created_at: '',
    updated_at: '',
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const defaultValues = {
      id: '',
      title_en: '',
      title_ar: '',
      content_en: '',
      content_ar: '',
      media: [],
      hidden: '',
      created_at: '',
      updated_at: '',
    };
  
    setEditedData(blogData || defaultValues);
    setImages(blogData?.media || []);
  }, [blogData]);

  const handleChange = (name, value) => {
    let mappedValue = value;
    if (name === 'hidden') {
        mappedValue = value === 'Hidden' ? 1 : 2;
    }
    setEditedData((prevData) => ({ ...prevData, [name]: mappedValue }));
};

  const validateFields = () => {
    const errors = {};

    if (!editedData.title_en) {
      errors.title_en = 'Title (English) is required';
    }

    if (!editedData.title_ar) {
      errors.title_ar = 'Title (Arabic) is required';
    }

    if (!editedData.content_en) {
      errors.content_en = 'Content (English) is required';
    }

    if (!editedData.content_ar) {
      errors.content_ar = 'Content (Arabic) is required';
    }

    if (!editedData.hidden) {
      errors.hidden = 'Hidden or Live is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? '*' : '';
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      const s3 = new AWS.S3({
        accessKeyId: "AKIARBDTOX44C3KS5CGY",
        secretAccessKey: "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",
        region: "me-south-1",
      });
      var media = {
        blog_img: [],        
      };
      const uploadPromises = files.map(async (file) => {
        const params = {
          Bucket: "natinda", // Replace with your S3 bucket name
          Key: `BlogsImage/${file.name}`, // Specify the path where you want to save the photo in S3
          Body: file,
          ContentType: file.type,
        };
        const data = await s3.upload(params).promise();
        // console.log("File uploaded successfully:", data);
  
        // The URL of the uploaded file
        const fileUrl = data.Location;
        media.blog_img = [...media.blog_img, fileUrl];
      });

      await Promise.all(uploadPromises);

      try {
      console.log(media);
      const imagesproduct = await fetch(
        `https://dashboard.natindamiddleeast.com/api/addMedia?id=$`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(media),
        }
      );

      await imagesproduct.json();
      }
      catch (error) {
        console.error("Error updating product:", error);
      } finally {
        
      }
      
    };

      onSubmit(editedData);
      onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers>
        {editedData && (
          <Box>
            <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Basic Information
            </Typography>

            <TextField
              label={`Blog ID ${renderAsterisk('id')}`}
              value={editedData.id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <TextField
              label={`Title (English) ${renderAsterisk('title_en')}`}
              value={editedData.title_en || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('title_en', e.target.value)}
              error={!!validationErrors.title_en}
              helperText={validationErrors.title_en}
            />

            <TextField
              label={`Title (Arabic) ${renderAsterisk('title_ar')}`}
              value={editedData.title_ar || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('title_ar', e.target.value)}
              error={!!validationErrors.title_ar}
              helperText={validationErrors.title_ar}
            />

            <TextField
              label={`Content (English) ${renderAsterisk('content_en')}`}
              value={editedData.content_en || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('content_en', e.target.value)}
              error={!!validationErrors.content_en}
              helperText={validationErrors.content_en}
            />

            <TextField
              label={`Content (Arabic) ${renderAsterisk('content_ar')}`}
              value={editedData.content_ar || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('content_ar', e.target.value)}
              error={!!validationErrors.content_ar}
              helperText={validationErrors.content_ar}
            />
           <div className="card">
                <div className="top">
                  <p>Blogs Images</p>
                </div>
                <div
                  className="drag-area"
                  onDragOver={onDragOverProductImg}
                  onDragLeave={onDragLeaveProductImg}
                  onDrop={onDrop}
                >
                  {isDragging ? (
                    <span className="select">Drop Images here</span>
                  ) : (
                    <>
                      Drag & Drop here or{" "}
                      <span
                        className="select"
                        role="button"
                        onClick={selectFiles}
                      >
                        Browse
                      </span>
                    </>
                  )}
                  <input
                    name="file"
                    type="file"
                    className="file"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  ></input>
                </div>
                <div className="container">
              
                  {images.map((image, index) => (
                    <div className="image-container" key={index}>
                      <div
                        className="image"
                        onDoubleClick={() =>
                          handleThumbnailDoubleClick(image.url)
                        }
                      >
                        <span
                          className="delete"
                          onClick={() => deleteImageProductImg(index)}
                        >
                          &times;
                        </span>
                        <img src={image.url} alt={image.name} />
                      </div>
                    </div>
                  ))}
                  {enlargedImage && (
                    <div className="image-modal" onClick={closeEnlargedImage}>
                      <div className="modal-content">
                        <span className="close" onClick={closeEnlargedImage}>
                          &times;
                        </span>
                        <img src={enlargedImage} alt="Enlarged Image" />
                      </div>
                    </div>
                  )}
                </div>
              </div>

            <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.hidden)}>
                <InputLabel>Hidden or Live? *</InputLabel>
                <Select
                value={editedData.hidden === 1 ? 'Hidden' : 'Live'}
                onChange={(e) => handleChange('hidden', e.target.value)}
                label="Select Hidden or Live?"
                >
                <MenuItem value="Hidden">Hidden</MenuItem>
                <MenuItem value="Live">Live</MenuItem>
                </Select>

                <Typography variant="body2" color="error">
                    {validationErrors.hidden}
                </Typography>
                </FormControl>

          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBlogDialog;
