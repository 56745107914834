import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useAuth } from "AuthContext";

const API_URL = "https://dashboard.natindamiddleeast.com/api";
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const Layout = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchUserData = async (retries = 0) => {
      try {
        const token = localStorage.getItem("api_token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await fetch(`${API_URL}/getUser`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }

        const responseData = await response.json();
        setUserData(responseData); // Successfully fetched user data
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (retries < MAX_RETRIES) {
          console.log(`Retrying... (${retries + 1}/${MAX_RETRIES})`);
          setTimeout(() => fetchUserData(retries + 1), RETRY_DELAY);
        } else {
          navigate("/login", { state: { error: error.message } });
        }
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      {userData ? (
        <>
          <Sidebar
            user={userData}
            isNonMobile={isNonMobile}
            drawerWidth="250px"
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <Box flexGrow={1}>
            <Navbar
              user={userData}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
            <Outlet />
          </Box>
        </>
      ) : (
        <div>Loading user data...</div>
      )}
    </Box>
  );
};

export default Layout;
