import React, { useState, useEffect } from 'react';
import api from 'state/api';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "react-js-loader";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as XLSX from 'xlsx';
import EditUserDialog from './editDialog';
import AddUserDialog from './addDialog';
import Pagination from 'components/pagination';
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import  { Input } from '@trendmicro/react-form-control';
const Users = () => {
  // State variables
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(100);

    /// filers
  const [selectedUserId, setSelectedUserId] = useState('');
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchPhone, setSearchPhone] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedAgeRange, setSelectedAgeRange] = useState('');
  const [selectedPointsRange, setSelectedPointsRange] = useState('');
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success', 
  });

  // Single Delete
  const [deletingUserId, setDeletingUserId] = useState('');
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // Multi Delete
  const [deletingUserIds, setDeletingUserIds] = useState([]);
  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  // Edit user data Dialog
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  // Add new order Dialog
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);

  

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await api.getUsers();
        setUsers(response.users);
        setSelectAll(false);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage]);

  // Select all + Pagination

  const checkAgeRange = (age, range) => {
    if (range === '46+') {
      return age >= 46;
    }
  
    const [min, max] = range.split('-').map(Number);
    return age >= min && age <= max;
  };
  

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }

    return age;
  };

  const checkPointsRange = (points, range) => {
    if (range.includes('+')) {
      const minPoints = parseInt(range);
      return points >= minPoints;
    } else {
      const [min, max] = range.split('-').map(Number);
      return points >= min && points <= max;
    }
  };
  
  
  const filteredUsers = users.filter((user) => {
    const age = calculateAge(user.birthday);

    const userFilter = selectedUserId === null ? user.id === null : !selectedUserId || user.id?.toString().includes(selectedUserId);
    const firstNameFilter = !searchFirstName || user.first_name.toLowerCase().includes(searchFirstName.toLowerCase());
    const lastNameFilter = !searchLastName || user.last_name.toLowerCase().includes(searchLastName.toLowerCase());
    const emailFilter = !searchEmail || user.email.toLowerCase().includes(searchEmail.toLowerCase());
    const PhoneFilter = !searchPhone || user.phone.includes(searchPhone);
    const RoleFilter = !selectedRole || user.role.includes(selectedRole);
    const isAgeInRange = selectedAgeRange === '' || checkAgeRange(age, selectedAgeRange);
    const isPointsInRange = selectedPointsRange === '' || checkPointsRange(user.points, selectedPointsRange);

    return (
      userFilter &&
      firstNameFilter &&
      lastNameFilter &&
      PhoneFilter &&
      emailFilter &&
      RoleFilter &&
      isAgeInRange &&
      isPointsInRange
    );
  });
  // Calculate the range of users to display based on pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredUsers.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  // Handle checkbox change for individual users
  const handleCheckboxChange = (userId) => {
    const updatedUsers = users.map((user) =>
      user.id === userId ? { ...user, selected: !user.selected } : user
    );
    setUsers(updatedUsers);
  };

  // Handle checkbox change for all users
  const handleSelectAll = () => {
    const updatedUsers = users.map((user) =>
      currentUsers.some((currentUser) => currentUser.id === user.id)
        ? { ...user, selected: !selectAll }
        : user
    );
    setUsers(updatedUsers);
    setSelectAll(!selectAll);
  };

  // Format updated and created at data
  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Africa/Cairo',
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  /// Filters
  const handleUserIdChange = (e) => {
    setSelectedUserId(e.target.value);
    setCurrentPage(1);
  };
  const handleFirstNameChange = (e) => {
    setSearchFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setSearchLastName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setSearchEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setSearchPhone(e.target.value);
  };
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setCurrentPage(1);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Alert
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ ...alert, open: false });
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle single delete  
  const handleDeleteSingle = (orderId) => {
    setDeletingUserId(orderId);
    setDeleteDialogOpen(true);
    };
  const handleDeleteConfirmationSingle = async () => {
      try {
        setLoading(true);
        setDeleteDialogOpen(false);
        await api.removeUser(deletingUserId);
        const response = await api.getUsers();
        setUsers(response.users);
          setAlert({
          open: true,
          message: 'User deleted successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error("Error deleting user:", error);
          setAlert({
          open: true,
          message: 'Failed to delete user. Please try again.',
          severity: 'error',
        });
      } finally {
        setDeletingUserId(null);
        setLoading(false);
      }
    };
  const handleDeleteCancelSingle = () => {
      setDeleteDialogOpen(false);
      setDeletingUserId(null);
    };
//////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle delete from select
  const handleDelete = () => {
    const selectedUserIds = users.filter((user) => user.selected).map((user) => user.id);
    if (selectedUserIds.length === 0) {
      console.log('No Users selected for deletion');
      return;
    }
    setDeletingUserIds(selectedUserIds);
    setDeleteConfirmationDialogOpen(true);
  };
  const handleDeleteConfirmation = async () => {
    try {
      setDeleteConfirmationDialogOpen(false);
      setLoading(true);
      await api.removeUsers({ userIds: deletingUserIds });
      const response = await api.getUsers();
      setUsers(response.users);
        setAlert({
        open: true,
        message: 'User deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error("Error deleting users:", error);
      setAlert({
        open: true,
        message: 'Failed to delete users. Please try again.',
        severity: 'error',
      });
    } finally {
      setDeletingUserIds(null);
      setLoading(false);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteConfirmationDialogOpen(false);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
 // Handle reset filters / Export
  const handleResetFilters = () => {
    setSelectedUserId('');
    setSearchFirstName('');
    setSearchLastName('');
    setSearchEmail('');
    setSearchPhone('');
    setSelectedRole('');
    setSelectedAgeRange('');
    setSelectedPointsRange('');
    setCurrentPage(1); 
  };
  const handleExport = () => {
    const selectedUsers = filteredUsers.filter((user) => user.selected);

    if (selectedUsers.length === 0) {
      console.log('No selected users to export');
      return;
    }

    // Prepare data for export
    const dataForExport = selectedUsers.map((user) => ({
      'User ID': user.id,
      'First Name': user.first_name,
      'Last Name': user.last_name,
      'Email': user.email,
      'Birthday': user.birthday,
      'Phone': user.phone,
      'About us': user.about_us,
      'Accept Promotion': user.accept_promotion,
      'Occupation': user.occupation,
      'Points': user.points,
      'Role': user.role,
      'Created At': formatDate(user.created_at),
      'Updated At': formatDate(user.updated_at),
    }));

    // Create a worksheet and set the data
    const ws = XLSX.utils.json_to_sheet(dataForExport);

    // Create a workbook with a single worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    // Save the file
    XLSX.writeFile(wb, 'users.xlsx');
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Edit existing user
  const handleEditClick = (order) => {
    setSelectedUserData(order);
    setEditDialogOpen(true);
  };
  const handleEditSubmit = async (editedData) => {
    try {
      console.log('Submitting edited data:', editedData);
      setLoading(true);
  
      const response = await api.updateUser(editedData);
      console.log('API response:', response);
  
      const usersResponse = await api.getUsers();
      setUsers(usersResponse.users);
  
      setAlert({
        open: true,
        message: 'User updated successfully!',
        severity: 'success',
      });
      
    } catch (error) {
      console.error('Error submitting edited data:', error);
      setAlert({
        open: true,
        message: 'Failed to update user. Please try again.',
        severity: 'error',
      });
    } finally {
      setSelectedUserData(null);
      setLoading(false);
    }
  };
  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// Add new user
  const handleAddUserOrderClick = () => {
    setAddNewUserDialogOpen(true);
  };

  const handleAddNewUserSubmit = async (data) => {
    try {
        console.log('Submitting New data:', data);

        setLoading(true);

        await api.addUser(data);

        const response = await api.getUsers();
        setUsers(response.users);

        setAlert({
            open: true,
            message: 'User Added successfully!',
            severity: 'success',
        });

        setAddNewUserDialogOpen(false);
    } catch (error) {
        console.error('Error submitting adding new user:', error);
        if (error.response  ) {
            const errors = error.response.data.errors;
            console.log(errors)
            if (errors.email) {
                setAlert({
                    open: true,
                    message: 'Email is already taken. Please choose a different email.',
                    severity: 'error',
                });
            } else if (errors.phone) {
                setAlert({
                    open: true,
                    message: 'Phone number is already taken. Please choose a different phone number.',
                    severity: 'error',
                });
            }
        } else {
            setAlert({
                open: true,
                message: 'Failed to update user. Please try again.',
                severity: 'error',
            });
        }
    } finally {
        setLoading(false);
    }
};



  return (
    <div className="container">
        <div className="filter-section">
          <div className="filter-set">
            <FormControl className="filter-form-control">
                <InputLabel>User Id</InputLabel>
                  <Select value={selectedUserId || ''} onChange={handleUserIdChange}>
                    <MenuItem value="" disabled>Select User ID</MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.id}- {user.first_name} {user.last_name} - {user.phone}
                      </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className="filter-form-control">
              <Input
                value={searchFirstName}
                onChange={handleFirstNameChange}
                placeholder ="First name"
              />
            </FormControl>

            <FormControl className="filter-form-control">
              <Input
                value={searchLastName}
                onChange={handleLastNameChange}
                placeholder ="Last name"
              />
            </FormControl>

            <FormControl className="filter-form-control">
              <Input
                value={searchEmail}
                onChange={handleEmailChange}
                placeholder ="Email"
              />
            </FormControl>

            <FormControl className="filter-form-control">
              <Input
                value={searchPhone}
                onChange={handlePhoneChange}
                placeholder ="Phone"
              />
            </FormControl>
          </div>
          <div className="filter-set">
            <FormControl className="filter-form-control">
              <InputLabel>Age Range</InputLabel>
              <Select value={selectedAgeRange || ''} onChange={(e) => setSelectedAgeRange(e.target.value)}>
                <MenuItem value="" disabled>Select Age Range</MenuItem>
                <MenuItem value="13-20">13 to 20</MenuItem>
                <MenuItem value="21-25">21 to 25</MenuItem>
                <MenuItem value="26-30">26 to 30</MenuItem>
                <MenuItem value="31-36">31 to 36</MenuItem>
                <MenuItem value="36-45">36 to 45</MenuItem>
                <MenuItem value="46+">45 and above</MenuItem> 
              </Select>
            </FormControl>
            <FormControl className="filter-form-control" >
              <InputLabel>Role</InputLabel>
              <Select value={selectedRole || ''}  onChange={handleRoleChange}>
                <MenuItem disabled> Select Status</MenuItem>
                <MenuItem value="authenticated">Authenticated</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="filter-form-control" >
              <InputLabel>Points Range</InputLabel>
                <Select value={selectedPointsRange || ''} onChange={(e) => setSelectedPointsRange(e.target.value)}>
                  <MenuItem value="" disabled>Select Points Range</MenuItem>
                  <MenuItem value="0-100">0 to 100</MenuItem>
                  <MenuItem value="101-1000">101 to 1000</MenuItem>
                  <MenuItem value="1001-2000">1001 to 2000</MenuItem>
                  <MenuItem value="2001+">2001 and above</MenuItem>
                </Select>
            </FormControl>
          </div>
        </div>

        <div className="actions-section">
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddUserOrderClick()}
                >
                    <AddIcon /> Add New User
                </Button>
                </Box>
                <div className="actions-container">
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                    >
                        <DeleteIcon /> Delete
                    </Button>
                    </Box>
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleExport}
                    >
                        <SaveAltIcon /> Export
                    </Button>
                    </Box>
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleResetFilters}
                    >
                        <RefreshIcon /> Reset filters
                    </Button>
                    </Box>
                </div>  
            </div>
        {loading ? (
        <div className="item">
          <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
      ) : (
        <table className="lookup-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectAll}
                />
              </th>
              <th>User ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Birthday</th>
              <th>Phone</th>
              <th>About us</th>
              <th>Accept Promotion</th>
              <th>Occupation</th>
              <th>Points</th>
              <th>Role</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>  
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>
                  <input type="checkbox"
                    checked={user.selected || false}
                    onChange={() => handleCheckboxChange(user.id)} 
                />
                </td>
                <td>{user.id}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.birthday}</td>
                <td>{user.phone}</td>
                <td>{user.about_us}</td>
                <td>{user.accept_promotion}</td>
                <td>{user.occupation}</td>
                <td>{user.points}</td>
                <td>{user.role}</td>
                <td>{formatDate(user.created_at)}</td>
                <td>{formatDate(user.updated_at)}</td>
                <td> <IconButton size="small" onClick={() => handleEditClick(user)} ><EditIcon /></IconButton>
                    <IconButton size="small" onClick={() => handleDeleteSingle(user.id)}><DeleteIcon /></IconButton>
              </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        currentPage={currentPage}
        paginate={paginate}
        totalItems={filteredUsers.length}
        itemsPerPage={usersPerPage}
        prevButtonLabel="< Previous"
        nextButtonLabel="Next >"
        buttonColor="primary"
        activeButtonColor="contained"
        inactiveButtonColor="default"
        disabled={false}
      />
      
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
          <DialogContent className="dialog-content">
            <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this user?</Typography>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button onClick={handleDeleteCancelSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmationSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
              Delete
            </Button>
          </DialogActions>
      </Dialog>

      <Dialog open={isDeleteConfirmationDialogOpen} onClose={handleDeleteCancel}>
          <DialogContent className="dialog-content">
            <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete the selected users?</Typography>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button onClick={handleDeleteCancel} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmation} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
              Delete
            </Button>
          </DialogActions>
      </Dialog>
      <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleCloseAlert}>
        <MuiAlert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: '100%', fontSize: '1.5rem' }}
        >
          {alert.message}
        </MuiAlert>
      </Snackbar>

      <EditUserDialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)} userData={selectedUserData} onSubmit={handleEditSubmit}/>
      
      <AddUserDialog isOpen={addNewUserDialogOpen} onClose={() => setAddNewUserDialogOpen(false)} onSubmit={handleAddNewUserSubmit}/>

    </div>
  );
};

export default Users;


