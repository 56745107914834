import React, { useState, useEffect } from "react";
import "./products.css";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Grid,
} from "@mui/material";
import Header from "components/Header";
import api from "state/api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import Product from "./products";

const Products = () => {
  const [selectedOption, setSelectedOption] = useState("Choose Category");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [values, setValues] = useState([]);
  const [productsData, setProductsData] = useState(null);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [isLoading, setLoading] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 8;


  // Calculate the index of the last card on the current page
  const indexOfLastCard = currentPage * cardsPerPage;
  // Calculate the index of the first card on the current page
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // Slice the array of products to get only the cards for the current page
  const currentCards = productsData
    ? productsData.slice(indexOfFirstCard, indexOfLastCard)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    fetch("https://dashboard.natindamiddleeast.com/api/getAllTypeIdAdmin")
      .then((data) => data.json())
      .then((val) => setValues(val));
  }, []);

  useEffect(() => {
    fetchAllProducts();
  }, []);


  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };


  const handleDropdownChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const fetchAllProducts = async () => {
    try {
      const fetchedUserData = await api.getProducts();
      setProductsData(fetchedUserData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Products");
  
    // Add headers
    const headers = [
      "Product ID",
      "Name",
      "Type",
      "Price",
      "Status",
      "Quantity",
      "Size",
      "Discounted Price",
      "Description",
      "Details",
      "How To Use",
      "Material",
      "Ingredients",
      "Why We Love It",
      "Hero Ingredients",
      "Recommended For",
      "Created At",
      "Updated At",
    ];
  
    worksheet.addRow(headers);
  
    const stripHtmlTags = (str) => {
      if (!str) return str;
      return str.replace(/<[^>]*>?/gm, '');
    };
  
    // Add data rows
    productsData.forEach((product) => {
      const row = [
        product.id,
        product.name_en,
        product.product_type.name_en,
        product.price,
        product.status,
        product.quantity,
        product.size_en,
        product.discounted_price,
        product.description_en,
        product.details_en,
        stripHtmlTags(product.how_to_use_en),
        product.material_en,
        product.ingredients_en,
        stripHtmlTags(product.why_we_love_it_en),
        product.hero_ingredients_en,
        stripHtmlTags(product.recommended_for_en),
        formatDate(product.created_at),
        formatDate(product.updated_at),
      ];
  
      worksheet.addRow(row);
    });
  
    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Products.xlsx");
  };
  


  // Format updated and created at data
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Africa/Cairo",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleFilterSubmit = async () => {
    try {
      const apiUrl =
        "https://dashboard.natindamiddleeast.com/api/getAllProductsAdmin";
      const queryParams = new URLSearchParams();

      if (minPrice) {
        queryParams.append("minPrice", minPrice);
      }

      if (maxPrice) {
        queryParams.append("maxPrice", maxPrice);
      }

      if (selectedOption !== "Choose Category") {
        queryParams.append("typeId", selectedOption);
      }

      const queryString = queryParams.toString();

      const response = await fetch(`${apiUrl}?${queryString}`);
      const data = await response.json();

      setProductsData(data);
      setIsFilterApplied(true);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleDeleteFilter = () => {
    setMinPrice("");
    setMaxPrice("");
    setSelectedOption("Choose Category");
    setIsFilterApplied(false);
    fetchAllProducts();
    setCurrentPage(1);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PRODUCTS" />

      <Box
        mb="1rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          Total number of products: {productsData ? productsData.length : 0}
        </Typography>

      </Box>

  
      {/* Filter Section */}
      <Grid container spacing={2} alignItems="center" mb="2rem">
        {/* Select Category Dropdown */}
        <Grid item>
          <FormControl variant="outlined" style={{ width: "150px" }}>
            <InputLabel>Select Category</InputLabel>
            <Select
              value={selectedOption}
              onChange={(e) => handleDropdownChange(e.target.value)}
              label="Select Category"
            >
              <MenuItem value="Choose Category">Choose Category</MenuItem>
              {values.map((opts, i) => (
                <MenuItem key={i} value={opts}>
                  {opts}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Min Price TextField */}
        <Grid item>
          <TextField
            label="Min Price"
            variant="outlined"
            value={minPrice}
            onChange={handleMinPriceChange}
          />
        </Grid>

        {/* Max Price TextField */}
        <Grid item>
          <TextField
            label="Max Price"
            variant="outlined"
            value={maxPrice}
            onChange={handleMaxPriceChange}
          />
        </Grid>

        {/* Apply Filters and Delete Filter Buttons */}
        <Grid item>
          <Button
            variant="contained"
            onClick={handleFilterSubmit}
            style={{ marginRight: "1rem" }}
          >
            Apply Filters
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteFilter}
            disabled={!isFilterApplied}
            style={{ marginRight: "1rem" }}
          >
            Delete Filter
          </Button>

          <Button variant="contained" color="secondary" onClick={handleExport}>
            <SaveAltIcon /> Export
          </Button>
        </Grid>
      </Grid>
      {currentCards.length > 0 ? (
        <>
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {currentCards.map(
              ({
                id,
                name_en,
                description_en,
                price,
                average_rating,
                product_type,
                quantity,
                status,
              }) => (
                <Product
                  key={id}
                  _id={id}
                  name_en={name_en}
                  description_en={description_en}
                  price={price}
                  rating={average_rating}
                  status={status}
                  type_id={product_type.name_en}
                  quantity={quantity}
                  fetchAllProducts={fetchAllProducts}
                />
              )
            )}
          </Box>
          <Box mt="20px" display="flex" justifyContent="center">
            <Button
              onClick={() => paginate(currentPage - 1)}
              variant="outlined"
              color="primary"
              disabled={currentPage === 1}
            >
              {"< Previous"}
            </Button>
            <Button variant="contained" color="primary" disabled>
              {currentPage}
            </Button>
            <Button
              onClick={() => paginate(currentPage + 1)}
              variant="outlined"
              color="primary"
              disabled={indexOfLastCard >= productsData.length}
            >
              {"Next >"}
            </Button>
          </Box>
        </>
      ) : (
        <>No products found...</>
      )}
      {/* Snackbar for displaying errors */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={closeSnackbar}
          severity="error"
        >
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Products;
