import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    TextField,
} from '@mui/material';

const initialFAQData = {
    product_id: '',
    question_en: '',
    question_ar: '',
    answer_en: '',
    answer_ar: '',
};

const boldTypographyStyle = { fontSize: '16px', fontWeight: 'bold' };

const AddFAQDialog = ({ isOpen, onClose, onSubmit, products }) => {
    const [newFAQData, setNewFAQData] = useState(initialFAQData);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (isOpen) {
            setNewFAQData(initialFAQData);
        }
    }, [isOpen]);

    const handleChange = (name, value) => {
        setNewFAQData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateFields = () => {  
        const errors = {};

        if (!newFAQData.product_id) {
            errors.product_id = 'Product ID is required';
        }
        if (!newFAQData.question_en) {
            errors.question_en = 'Question (English) is required';
        }
        if (!newFAQData.question_ar) {
            errors.question_ar = 'Question (Arabic) is required';
        }
        if (!newFAQData.answer_en) {
            errors.answer_en = 'Answer (English) is required';
        }
        if (!newFAQData.answer_ar) {
            errors.answer_ar = 'Answer (Arabic) is required';
        }
        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const renderAsterisk = (fieldName) => {
        return validationErrors[fieldName] ? '*' : '';
    };

    const handleSubmit = () => {
        if (validateFields()) {
            onSubmit(newFAQData);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <Box>
                        <Typography variant="h6" gutterBottom style={boldTypographyStyle}>
                            FAQ Information
                        </Typography>

                        <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.product_id)}>
                            <InputLabel>Product ID *</InputLabel>
                            <Select
                                value={newFAQData.product_id}
                                onChange={(e) => handleChange('product_id', e.target.value)}
                                label="Select Product ID"
                            >
                                {products.map((product) => (
                                    <MenuItem key={product.id} value={product.id}>
                                        {product.id}- {product.name_en}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText variant="caption" error>
                                {validationErrors.product_id}
                            </FormHelperText>
                        </FormControl>

                        <TextField
                            label={`Question (English) ${renderAsterisk('question_en')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('question_en', e.target.value)}
                            error={!!validationErrors.question_en}
                            helperText={validationErrors.question_en}
                        />
                        <TextField
                            label={`Question (Arabic) ${renderAsterisk('question_ar')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('question_ar', e.target.value)}
                            error={!!validationErrors.question_ar}
                            helperText={validationErrors.question_ar}
                        />
                        <TextField
                            label={`Answer (English) ${renderAsterisk('answer_en')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('answer_en', e.target.value)}
                            error={!!validationErrors.answer_en}
                            helperText={validationErrors.answer_en}
                        />
                        <TextField
                            label={`Answer (Arabic) ${renderAsterisk('answer_ar')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('answer_ar', e.target.value)}
                            error={!!validationErrors.answer_ar}
                            helperText={validationErrors.answer_ar}
                        />
                    </Box>
                </DialogContent>
            </form>
            <DialogActions>
                <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddFAQDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
};

export default AddFAQDialog;
