import React, { useState, useEffect } from 'react';
import api from 'state/api';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "react-js-loader";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as XLSX from 'xlsx';
import Pagination from 'components/pagination';
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import EditFAQDialog from './editDialog';
import AddFAQDialog from './addDialog';
const FAQ = () => {
  // State variables
    const [faqs, setFaqs] = useState([]);
    const [products, setProducts] = useState([]);

    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [faqPerPage] = useState(100);

    // filters
    const [selectedFAQId, setSelectedFAQId] = useState('');
    const [selectedProductId, setSelectedProductId] = useState('');

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success', 
    });
    // Single Delete
    const [deletingFAQ, setDeletingFAQId] = useState('');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // Multi Delete
    const [deletingFAQIds, setDeletingFAQIds] = useState([]);
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

      // Edit faq data Dialog
    const [selectedFAQData, setSelectedFAQData] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
        // Add new promo code Dialog
    const [addNewFAQDialogOpen, setAddNewFAQDialogOpen] = useState(false);

    useEffect(() => {
        const fetchFAQ = async () => {
        try {
            setLoading(true);
            const response = await api.getFAQ();
            setFaqs(response.faq);
            setProducts(response.product);
            setSelectAll(false);
        } catch (error) {
            console.error('Error fetching FAQ:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchFAQ();
    }, [currentPage]);
    const filteredFAQS = faqs.filter((faq) => {
        const faqfilter = selectedFAQId ? faq.id === selectedFAQId : true;
        const productIdfilter = selectedProductId ? faq.product_id === selectedProductId : true;
        return (
            faqfilter &&
            productIdfilter
        );
    });
      // Calculate the range of Reviews to display based on pagination
        const indexOfLastFAQ = currentPage * faqPerPage;
        const indexOfFirstFAQ = indexOfLastFAQ - faqPerPage;
        const currentFAQ = filteredFAQS.slice(indexOfFirstFAQ, indexOfLastFAQ);
        
        const paginate = (pageNumber) => setCurrentPage(pageNumber);
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredFAQS.length / faqPerPage); i++) {
            pageNumbers.push(i);
        }
      // Handle checkbox change for individual FAQ
    const handleCheckboxChange = (faqId) => {
        const updatedFAQ = faqs.map((faq) =>
        faq.id === faqId ? { ...faq, selected: !faq.selected } : faq
        );
        setFaqs(updatedFAQ);
    };
      // Handle checkbox change for all FAQs
    const handleSelectAll = () => {
        const updatedFAQ = faqs.map((faq) =>
        currentFAQ.some((currentFAQ) => currentFAQ.id === faq.id)
            ? { ...faq, selected: !selectAll }
            : faq
        );
        setFaqs(updatedFAQ);
        setSelectAll(!selectAll);
    };
      // Format updated and created at data
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Africa/Cairo',
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };
      /// Filters
    const handleFAQIdChange = (e) => {
        setSelectedFAQId(e.target.value);
        setCurrentPage(1);
    };
    const handleProductIdChange = (e) => {
        setSelectedProductId(e.target.value);
        setCurrentPage(1);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Alert
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setAlert({ ...alert, open: false });
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
// Handle single delete
    const handleDeleteSingle = (faqId) => {
        setDeletingFAQId(faqId);
        setDeleteDialogOpen(true);
    };
    
    const handleDeleteConfirmationSingle = async () => {
        try {
        setLoading(true);
        setDeleteDialogOpen(false);
        await api.removeFAQ(deletingFAQ);
        const response = await api.getFAQ();
        setFaqs(response.faq);
        setAlert({
            open: true,
            message: 'FAQ deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting FAQ:", error);
        setAlert({
            open: true,
            message: 'Failed to delete FAQ. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingFAQId(null);
        setLoading(false);
        }
    };
    
    const handleDeleteCancelSingle = () => {
        setDeleteDialogOpen(false);
        setDeletingFAQId(null);
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  
  // Handle delete from select
    const handleDelete = () => {
        const selectedFAQIds = faqs.filter((faq) => faq.selected).map((faq) => faq.id);
        if (selectedFAQIds.length === 0) {
        console.log('No FAQs selected for deletion');
        return;
        }
        setDeletingFAQIds(selectedFAQIds);
        setDeleteConfirmationDialogOpen(true);
    };
    const handleDeleteConfirmation = async () => {
        try {
        setDeleteConfirmationDialogOpen(false);
        setLoading(true);
        await api.removeFAQs({ faqIds: deletingFAQIds });
        const response = await api.getFAQ();
        setFaqs(response.faq);
        setAlert({
            open: true,
            message: 'FAQ deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting FAQ:", error);
        setAlert({
            open: true,
            message: 'Failed to delete FAQ. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingFAQIds(null);
        setLoading(false);
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmationDialogOpen(false);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle reset filters / Export
    const handleResetFilters = () => {
        setSelectedFAQId('');
        setSelectedProductId('');
        setCurrentPage(1);
    };
    const handleExport = () => {
        const selectedFAQ = filteredFAQS.filter((faq) => faq.selected);
    
        if (selectedFAQ.length === 0) {
        console.log('No selected FAQ to export');
        return;
        }
    
        // Prepare data for export
        const dataForExport = selectedFAQ.map((faq) => ({
        'Review ID': faq.id,
        'Product ID': faq.product_id,
        'Question English': faq.question_en,
        'Question Arabic': faq.question_ar,
        'Answer in English': faq.answer_en,
        'Answer in Arabic': faq.answer_ar,
        'Created At': formatDate(faq.created_at),
        'Updated At': formatDate(faq.updated_at),
        }));
    
        // Create a worksheet and set the data
        const ws = XLSX.utils.json_to_sheet(dataForExport);
    
        // Create a workbook with a single worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'FAQs');
    
        // Save the file
        XLSX.writeFile(wb, 'faqs.xlsx');
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Edit existing faq
    const handleEditClick = (data) => {
        setSelectedFAQData(data);
        setEditDialogOpen(true);
    };
    const handleEditSubmit = async (editedData) => {
        try {
        setLoading(true);
        await api.updateFAQ(editedData);
        const Response = await api.getFAQ();
        setFaqs(Response.faq);
    
        setAlert({
            open: true,
            message: 'FAQ updated successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error('Error submitting edited data:', error);
        setAlert({
            open: true,
            message: 'Failed to update FAQ. Please try again.',
            severity: 'error',
        });
        } finally {
        setSelectedFAQData(null);
        setLoading(false);
        }
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /// Add new FAQ
    const handleFAQClick = () => {
        setAddNewFAQDialogOpen(true);
    };

    const handleAddFAQSubmit = async (data) => {
        try {
            setLoading(true);
            await api.addFAQ(data);
            const response = await api.getFAQ();
            setFaqs(response.faq);

            setAlert({
                open: true,
                message: 'FAQ Added successfully!',
                severity: 'success',
            });

            setAddNewFAQDialogOpen(false);
        } catch (error) {
            setAlert({
                open: true,
                message: 'Failed to update faq. Please try again.',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };   

    return (
    <div className="container">
        <div className="filter-section">
            <div className="filter-set">
                <FormControl className="filter-form-control">
                    <InputLabel>FAQ Id</InputLabel>
                    <Select value={selectedFAQId || ''} onChange={handleFAQIdChange}>
                        <MenuItem value="" disabled>Select FAQ ID</MenuItem>
                        {faqs.map((faq) => (
                        <MenuItem key={faq.id} value={faq.id}>
                            {faq.id}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="filter-form-control">
                    <InputLabel>Product Id</InputLabel>
                    <Select value={selectedProductId || ''} onChange={handleProductIdChange}>
                        <MenuItem value="" disabled>Select Product ID</MenuItem>
                        {products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                            {product.id}- {product.name_en} 
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className="actions-section">
            <Box     
                mb="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            > 
            <Button
                variant="contained"
                color="primary"
                onClick={handleFAQClick}
            >
                <AddIcon /> Add New FAQ
            </Button>
            </Box>
            <div className="actions-container">
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                >
                    <DeleteIcon /> Delete
                </Button>
                </Box>

                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleExport}
                >
                    <SaveAltIcon /> Export
                </Button>
                </Box>
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleResetFilters}
                >
                    <RefreshIcon /> Reset filters
                </Button>
                </Box>
            </div>  
        </div>
        {loading ? (
        <div className="item">
            <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
        ) : (
        <table className="lookup-table">
            <thead>
            <tr>
                <th>
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectAll}
                />
                </th>
                <th>FAQ ID</th>
                <th>Product ID</th>
                <th>Question in English</th>
                <th>Question in Arabic</th>
                <th>Answer in English</th>
                <th>Answer in Arabic</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {filteredFAQS.map((faq) => (
                <tr key={faq.id}>
                <td>
                    <input
                    type="checkbox"
                    checked={faq.selected || false}
                    onChange={() => handleCheckboxChange(faq.id)}
                    />
                </td>
                <td>{faq.id}</td>
                <td>{products.map((product) => {
                    if (product.id === faq.product_id) {
                        return (
                        <div key={product.id}>
                            {product.id}- {product.name_en}
                        </div>
                        );
                    }
                    return null; 
                    })}
                </td>
                <td>{faq.question_en}</td>
                <td>{faq.question_ar}</td>
                <td>{faq.answer_en}</td>
                <td>{faq.answer_ar}</td>
                <td>{formatDate(faq.created_at)}</td>
                <td>{formatDate(faq.updated_at)}</td>
                <td>
                    <IconButton size="small" onClick={() => handleEditClick(faq)}>
                    <EditIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDeleteSingle(faq.id)}>
                    <DeleteIcon />
                    </IconButton>
                </td>
                </tr>
            ))}
            </tbody>
        </table>
        )}
        <Pagination
            currentPage={currentPage}
            paginate={paginate}
            totalItems={filteredFAQS.length}
            itemsPerPage={faqPerPage}
            prevButtonLabel="< Previous"
            nextButtonLabel="Next >"
            buttonColor="primary"
            activeButtonColor="contained"
            inactiveButtonColor="default"
            disabled={false}
        />
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
            <DialogContent className="dialog-content">
                <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this FAQ?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={handleDeleteCancelSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                Cancel
                </Button>
                <Button onClick={handleDeleteConfirmationSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                Delete
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={isDeleteConfirmationDialogOpen} onClose={handleDeleteCancel}>
            <DialogContent className="dialog-content">
                <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete the selected FAQ?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={handleDeleteCancel} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                Delete
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={alert.open} autoHideDuration={1000} onClose={handleCloseAlert}>
            <MuiAlert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: '100%', fontSize: '1.5rem' }}
            >
            {alert.message}
            </MuiAlert>
        </Snackbar>

        <EditFAQDialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)} faqData={selectedFAQData} products={products} onSubmit={handleEditSubmit}/>
        <AddFAQDialog isOpen={addNewFAQDialogOpen} onClose={() => setAddNewFAQDialogOpen(false)} onSubmit={handleAddFAQSubmit} products={products}/>

    </div>
    );
};

export default FAQ;
