import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./receipt.css";
import natindaLogo from "../../assets/Natinda-Logo-Receipt.svg";
import wogohBaraka from "../../assets/Wogoh-Baraka-Logo-Receipt.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SyncLoader } from "react-spinners";
import ErrorImage from "./../../assets/error.png";

const Receipt = (props) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(
          "https://dashboard.natindamiddleeast.com/api/fetchOrderDetailsById",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              order_id: id,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          setOrderDetail(data.order);
        } else {
          setError("OOPS! No Order Details Found");
        }
      } catch (error) {
        setError("Error fetching order details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, []);

  const downloadPDF = () => {
    const input = document.getElementById("receipt-container");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.save(`receipt_${orderDetail.id}.pdf`);
    });
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : error ? (
        <div className="error-container">
          <img src={ErrorImage} alt="error" />
          <div className="error-text">{error}</div>
        </div>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10%",
              paddingTop: "50px",
            }}
          >
            <button onClick={() => downloadPDF()}>Download Receipt</button>
          </div>
          <div id="receipt-container" className="receipt-container">
            <div className="receipt-head"> Receipt</div>
            <div className="receipt-header">
              <img
                src={natindaLogo}
                alt="natindaLogo"
                className="receipt-natinda-logo"
              />
              <img
                src={wogohBaraka}
                alt="wogohBaraka"
                className="receipt-wogoh-baraka"
              />
            </div>
            <div className="black-line" />
            <div className="billing-company-info-container">
              <div className="billing-coloum">
                <div className="billing-info-title">Billing Address</div>
                <div className="billing-info-body">
                  {orderDetail.shippingaddress.first_name}{" "}
                  {orderDetail.shippingaddress.last_name}
                  <br />
                  {orderDetail.shippingaddress.address}
                  <br />
                  {orderDetail.shippingaddress.city},{" "}
                  {orderDetail.shippingaddress.state},{" "}
                  {orderDetail.shippingaddress.country}
                  <br />
                  {orderDetail.shippingaddress.phone}
                </div>
              </div>
              <div className="billing-coloum2">
                <div className="billing-info-title">Company Information</div>
                <div className="billing-info-body2">
                  شركه الوجوه البراقة للتجاره 7783 شارع ابن كثير - حي الملك عبد
                  العزيز - الرياض - المملكة العربية السعودية Postal Code: 12233
                  Addi No. : 4264 VAT Number: 311782090300003 C.R No. 1010915062
                  <br />
                  Phone Number: +966 50 973 5725
                </div>
              </div>
            </div>
            <div className="black-line" />
            <div className="receipts-order-information">
              <div className="receipts-order-title">Order Information</div>
              <div>
                <span>Order #: {orderDetail.id}</span>
                <br />
                <span>
                  Order date:{" "}
                  {new Date(orderDetail.created_at).toLocaleDateString()}
                </span>
                <br />
                <span>
                  Order placed by: {orderDetail.shippingaddress.first_name}{" "}
                  {orderDetail.shippingaddress.last_name}
                </span>
                <br />
                <span>Shipping Method: Standard</span>
                <br />
                {orderDetail.is_paid ? (
                  <span>Payment Details: Card</span>
                ) : (
                  <span>Payment Details: Cash on Delivery</span>
                )}
              </div>
            </div>
            <div className="black-line" />
            <div className="receipts-order-items">
              <div className="receipts-order-title">Order Items</div>

              <div className="receipts-order-items-container">
                <div className="receipts-order-items-single">
                  <div>Description</div>
                  <div className="receipts-order-items-single-body">
                    {orderDetail.products.map((product, index) => (
                      <div key={index}>{product.name_en}</div>
                    ))}
                  </div>
                </div>

                <div className="receipts-order-items-single">
                  <div>QTY</div>
                  <div className="receipts-order-items-single-body">
                    {orderDetail.products.map((product, index) => (
                      <div key={index}>{product.pivot.quantity}</div>
                    ))}
                  </div>
                </div>

                <div className="receipts-order-items-single">
                  <div>Unit price</div>
                  <div className="receipts-order-items-single-body">
                    {orderDetail.products.map((product, index) => (
                      <div key={index}>{(product.price * 0.85).toFixed(1)}</div>
                    ))}
                  </div>
                </div>

                <div className="receipts-order-items-single">
                  <div>VAT%</div>
                  <div className="receipts-order-items-single-body">
                    {orderDetail.products.map((product, index) => (
                      <div key={index}>%15</div>
                    ))}
                  </div>
                </div>

                <div className="receipts-order-items-single">
                  <div>VAT</div>
                  <div className="receipts-order-items-single-body">
                    {orderDetail.products.map((product, index) => (
                      <div key={index}>
                        {(
                          product.price *
                          0.15 *
                          product.pivot.quantity
                        ).toFixed(2)}{" "}
                        SAR
                      </div>
                    ))}
                  </div>
                </div>

                <div className="receipts-order-items-single">
                  <div>Total value</div>
                  <div className="receipts-order-items-single-body">
                    {orderDetail.products.map((product, index) => (
                      <div key={index}>
                        {(product.price * product.pivot.quantity)}{" "}
                        SAR
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="black-line" />

            <div className="receipts-final-1">
              <div>All prices are VAT inclusive’</div>
              <div className="receipts-final-container">
                <div>
                  Discounts:{" "}
                  {orderDetail.promocode_price + orderDetail.points_price} SAR
                </div>
                <div> Shipping fees: {orderDetail.shipping_fees} SAR</div>
                <div> VAT: {(orderDetail.total_price * 0.15).toFixed(2)} SAR</div>
                <div> Total Paid: {orderDetail.total_price} SAR</div>
              </div>
            </div>

            <div className="black-line" />

            <div className="receipts-final-2">
              *سياسة الاستبدال والاسترجاع* في متجر ناتيندا، صحتك هي أولويتنا!
              للحفاظ على سلامة الجميع وصحة المجتمع، لا يمكن إرجاع أو استبدال
              معظم منتجاتنا. يضمن هذا تطبيق معايير النظافة والصحة العامة للجميع.
              ومع ذلك، نحن نتفهم أن الأخطاء واردة! إذا استلمت منتجًا معيبًا أو
              تالفًا أو تم إرساله عن طريق الخطأ، فلا تقلق! فقط أخبر فريق دعم
              العملاء الودود لدينا خلال 3 أيام من استلام طلبك، وسيسعدنا ترتيب
              استبدال أو استرداد لك. فريق دعم العملاء:
              Customersupport@natindamiddleeast.com
            </div>
          </div>
        </>
      )}

      {/* <button onClick={downloadPDF}>Download PDF</button> */}
    </>
  );
};

export default Receipt;
