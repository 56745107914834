import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditUserDialog = ({ isOpen, onClose, userData, onSubmit }) => {
  const [editedData, setEditedData] = useState({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    birthday: '',
    phone: '',
    about_us: '',
    occupation: '',
    points: '',
    role: '',
    created_at: '',
    updated_at: '',
  });

const [validationErrors, setValidationErrors] = useState({});

useEffect(() => {
    const defaultValues = {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        birthday: '',
        phone: '',
        about_us: '',
        occupation: '',
        points: '',
        role: '',
        created_at: '',
        updated_at: '',
    };
  
    setEditedData(userData || defaultValues);
  }, [userData]);
  

  const handleChange = (name, value) => {
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

const validateFields = () => {
    const errors = {};
  
    if (!editedData.first_name) {
        errors.first_name = 'First Name is required';
    }

    if (!editedData.last_name) {
        errors.last_name = 'Last Name is required';
    }

    if (!editedData.email) {
        errors.email = 'Email is required';
    }
    if (!editedData.phone) {
        errors.phone = 'Phone is required';
    }
    if (!editedData.about_us) {
        errors.about_us = 'About us is required';
    }

    if (!editedData.role) {
        errors.role = 'Role is required';
    }
    if (!/^\d{4}-\d{2}-\d{2}$/.test(editedData.birthday)) {
        errors.birthday = 'Invalid date format. Please use YYYY-MM-DD';
    }

    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
};
const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? '*' : '';
};

const handleSubmit = () => {
    if (validateFields()) {
        onSubmit(editedData);
        onClose();
    }
};  

return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent dividers>
            {editedData && (
            <Box>
            <Typography variant="h6" gutterBottom style={{fontSize: '16px',fontWeight: 'bold' }}>
                Basic Information
            </Typography>

            <TextField
                label={`User ID ${renderAsterisk('id')}`}
                value={editedData.id}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ readOnly: true }}
            />
            <TextField
                label={`First Name ${renderAsterisk('first_name')}`}
                value={editedData.first_name || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('first_name', e.target.value)}
                error={!!validationErrors.first_name}
                helperText={validationErrors.first_name}
            />
            <TextField
                label={`Last Name ${renderAsterisk('last_name')}`}
                value={editedData.last_name || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('last_name', e.target.value)}
                error={!!validationErrors.last_name}
                helperText={validationErrors.last_name}
            />
            <TextField
                label={`Email ${renderAsterisk('email')}`}
                value={editedData.email || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('email', e.target.value)}
                error={!!validationErrors.email}
                helperText={validationErrors.email}
            />
            <TextField
                label={`Birthday ${renderAsterisk('birthday')}`}
                value={editedData.birthday || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('birthday', e.target.value)}
                error={!!validationErrors.birthday}
                helperText={validationErrors.birthday}
            />
            <TextField
                label={`Phone ${renderAsterisk('phone')}`}
                value={editedData.phone || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('phone', e.target.value)}
                error={!!validationErrors.phone}
                helperText={validationErrors.phone}
            />
            <TextField
                label={`About Us ${renderAsterisk('about_us')}`}
                value={editedData.about_us || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('about_us', e.target.value)}
                error={!!validationErrors.about_us}
                helperText={validationErrors.about_us}
            />
            <TextField
                label={`Occupation`}
                value={editedData.occupation || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('occupation', e.target.value)}
            />

            <TextField
                label={`Points`}
                value={editedData.points || ''}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ readOnly: true }}
            />

            <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.role)}>
              <InputLabel>Role *</InputLabel>
                <Select
                    value={editedData.role }
                    onChange={(e) => handleChange('role', e.target.value)}
                    label="Select Is role"
                >
                <MenuItem value="authenticated">Authenticated</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                </Select>
                <Typography variant="body2" color="error">
                    {validationErrors.is_paid}
                </Typography>
            </FormControl>


        </Box>
        )}
    </DialogContent>
    <DialogActions>
        <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700',fontSize: '16px' ,fontWeight: 'bold' }}>
            Save Changes
        </Button>
    </DialogActions>
    </Dialog>
    );
};

export default EditUserDialog;
