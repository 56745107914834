import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const EditPromoCodeDialog = ({ isOpen, onClose, promoCodeData, onSubmit }) => {
  const [editedData, setEditedData] = useState({
    id: "",
    code: "",
    value: "",
    is_working: "",
    max_amount: "",
    max_number_of_used: "",
    expiry_date: "",
    created_at: "",
    updated_at: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const defaultValues = {
      id: "",
      code: "",
      value: "",
      is_working: "",
      max_amount: "",
      max_number_of_used: "",
      expiry_date: "",
      created_at: "",
      updated_at: "",
    };

    setEditedData(promoCodeData || defaultValues);
  }, [promoCodeData]);

  const handleChange = (name, value) => {
    let mappedValue = value;

    setEditedData((prevData) => ({ ...prevData, [name]: mappedValue }));
  };

  const validateFields = () => {
    const errors = {};

    if (!editedData.code) {
      errors.code = "Promo Code is required";
    }

    if (!editedData.value) {
      errors.value = "Value is required";
    }

    if (!editedData.is_working) {
      errors.is_working = "Working status is required";
    }
    if (!editedData.expiry_date) {
      errors.expiry_date = "Expiry date is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? "*" : "";
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(editedData);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers>
        {editedData && (
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Promo Code Information
            </Typography>

            <TextField
              label={`Promo Code ID ${renderAsterisk("id")}`}
              value={editedData.id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Code ${renderAsterisk("code")}`}
              value={editedData.code || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("code", e.target.value)}
              error={!!validationErrors.code}
              helperText={validationErrors.code}
            />
            <TextField
              label={`Value ${renderAsterisk("value")}`}
              value={editedData.value || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("value", e.target.value)}
              error={!!validationErrors.value}
              helperText={validationErrors.value}
            />
            <TextField
              label={`Amount deducted`}
              value={editedData.max_amount || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("max_amount", e.target.value)}
            />
            <TextField
              label={`Number of Uses Left`}
              value={editedData.max_number_of_used || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                handleChange("max_number_of_used", e.target.value)
              }
            />

            <TextField
              label={`Expiry date ${renderAsterisk("expiry_date")}`}
              value={editedData.expiry_date || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("expiry_date", e.target.value)}
              error={!!validationErrors.expiry_date}
              helperText={validationErrors.expiry_date}
            />

            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(validationErrors.is_working)}
            >
              <InputLabel>Is Working? *</InputLabel>
              <Select
                value={editedData.is_working}
                onChange={(e) => handleChange("is_working", e.target.value)}
                label="Select Is Working"
              >
                <MenuItem value="Working">Working</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
              </Select>

              <Typography variant="body2" color="error">
                {validationErrors.is_working}
              </Typography>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ color: "#ffd700", fontSize: "16px", fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          style={{ color: "#ffd700", fontSize: "16px", fontWeight: "bold" }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPromoCodeDialog;
