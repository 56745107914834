import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditProductTypeDialog = ({ isOpen, onClose, productType, onSubmit }) => {
const [editedData, setEditedData] = useState({
    id: '',
    name_en: '',
    name_ar: '',
    created_at: '',
    updated_at: '',
});

const [validationErrors, setValidationErrors] = useState({});

useEffect(() => {
    const defaultValues = {
    id: '',
    name_en: '',
    name_ar: '',
    created_at: '',
    updated_at: '',
    };

    setEditedData(productType || defaultValues);
}, [productType]);

const handleChange = (name, value) => {
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
};

const validateFields = () => {
    const errors = {};

    if (!editedData.name_en) {
        errors.name_en = 'Name English is required';
    }

    if (!editedData.name_ar) {
        errors.name_ar = 'Name Arabic is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
};


const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? '*' : '';
};

const handleSubmit = () => {
    if (validateFields()) {
    onSubmit(editedData);
    onClose();
    }
};

return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
    <DialogContent dividers>
        {editedData && (
        <Box>
            <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Product Type Information
            </Typography>

            <TextField
            label={`Promo Code ID ${renderAsterisk('id')}`}
            value={editedData.id}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{ readOnly: true }}
            />
            <TextField
            label={`Name English ${renderAsterisk('name_en')}`}
            value={editedData.name_en || ''}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('name_en', e.target.value)}
            error={!!validationErrors.name_en}
            helperText={validationErrors.name_en}
            />
            <TextField
            label={`Name Arabic ${renderAsterisk('name_ar')}`}
            value={editedData.name_ar || ''}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('name_ar', e.target.value)}
            error={!!validationErrors.name_ar}
            helperText={validationErrors.name_ar}
            />

        </Box>
        )}
    </DialogContent>
    <DialogActions>
        <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
        Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
        Save Changes
        </Button>
    </DialogActions>
    </Dialog>
);
};

export default EditProductTypeDialog;
