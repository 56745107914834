import React, { useState, useEffect } from 'react';
import api from 'state/api';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "react-js-loader";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as XLSX from 'xlsx';
import Pagination from 'components/pagination';
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import  { Input } from '@trendmicro/react-form-control';
import EditProductTypeDialog from './editDialog';
import AddProductTypeDialog from './addDialog';

const ProductType = () => {
    // State variables
    const [productTypes, setProductTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [productTypePerPage] = useState(100);

    /// filters
    const [selectedProductTypeId, setSelectedProductTypeId] = useState('');
    const [searchNameEn, setSearchNameEn] = useState('');
    const [searchNameAr, setSearchNameAr] = useState('');


    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    // Single Delete
    const [deletingProductTypeId, setDeletingProductTypeId] = useState('');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // Multi Delete
    const [deletingProductTypeIds, setDeletingProductTypeIds] = useState([]);
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    // Edit Product Type data Dialog
    const [selectedProductTypeData, setSelectedProductTypeData] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    // Add new Product Type Dialog
    const [addProductTypeDialogOpen, setAddNewProductTypeDialogOpen] = useState(false);

    useEffect(() => {
        const fetchProductType = async () => {
        try {
            setLoading(true);
            const response = await api.getProductTypes();
            setProductTypes(response.productType);
            setSelectAll(false);
        } catch (error) {
            console.error('Error fetching Product type:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchProductType();
    }, [currentPage]);

    const filteredProductType = productTypes.filter((productType) => {
        const productTypeFilter = selectedProductTypeId ? productType.id === selectedProductTypeId : true;
        const NameEnFilter = !searchNameEn || productType.name_en.toLowerCase().includes(searchNameEn.toLowerCase());
        const NameArFilter = !searchNameAr || productType.name_ar.toLowerCase().includes(searchNameAr.toLowerCase());
        return (
            productTypeFilter && 
            NameEnFilter &&
            NameArFilter
        );
    });
  // Calculate the range of Product Type to display based on pagination ProductType
    const indexOfLastProductType = currentPage * productTypePerPage;
    const indexOfFirstProductType = indexOfLastProductType - productTypePerPage;
    const currentProductTypes = filteredProductType.slice(indexOfFirstProductType, indexOfLastProductType);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProductType.length / productTypePerPage); i++) {
        pageNumbers.push(i);
    }

  // Handle checkbox change for individual product types
    const handleCheckboxChange = (productTypeid) => {
        const updatedproductTypes = productTypes.map((productType) =>
        productType.id === productTypeid ? { ...productType, selected: !productType.selected } : productType
        );
        setProductTypes(updatedproductTypes);
    };

    // Handle checkbox change for all 
    const handleSelectAll = () => {
        const updatedproductTypes = productTypes.map((productType) =>
        currentProductTypes.some((currentProductType) => currentProductType.id === productType.id)
            ? { ...productType, selected: !selectAll }
            : productType
        );
        setProductTypes(updatedproductTypes);
        setSelectAll(!selectAll);
    }; 

    // Format updated and created at data
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Africa/Cairo',
        };
            return new Date(dateString).toLocaleDateString('en-US', options);
        };
    /// Filters

    const handleProductTypeIdChange = (e) => {
        setSelectedProductTypeId(e.target.value);
        setCurrentPage(1);
    };
    const handleNameEnChange = (e) => {
        setSearchNameEn(e.target.value);
        setCurrentPage(1);
    };

    const handleNameArChange = (e) => {
        setSearchNameAr(e.target.value);
        setCurrentPage(1);
    };

         //////////////////////////////////////////////////////////////////////////////////////////////////
    // Alert
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setAlert({ ...alert, open: false });
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle single delete  
    const handleDeleteSingle = (productTypeId) => {
        setDeletingProductTypeId(productTypeId);
        setDeleteDialogOpen(true);
        };

    const handleDeleteConfirmationSingle = async () => {
            try {
                setLoading(true);
                setDeleteDialogOpen(false);
        
                console.log(deletingProductTypeId);
        
                const [deleteResponse] = await Promise.all([
                    api.removeProductType(deletingProductTypeId),
                ]);
        
                console.log('Delete Response:', deleteResponse);
        
                setAlert({
                    open: true,
                    message: 'Product type deleted successfully!',
                    severity: 'success',
                });
            } catch (error) {
                console.error('Error deleting product type:', error);
                setAlert({
                    open: true,
                    message: 'Failed to delete product type. Please try again.',
                    severity: 'error',
                });
            } finally {
                setDeletingProductTypeId(null);
                setLoading(false);
            }
        };
        

    const handleDeleteCancelSingle = () => {
            setDeleteDialogOpen(false);
            setDeletingProductTypeId(null);
        };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle delete from select

    const handleDelete = () => {
        const selectedproductTypeIds = productTypes.filter((productType) => productType.selected).map((productType) => productType.id);
        if (selectedproductTypeIds.length === 0) {
        console.log('No Product type selected for deletion');
        return;
        }
        setDeletingProductTypeIds(selectedproductTypeIds);
        setDeleteConfirmationDialogOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        try {
        setDeleteConfirmationDialogOpen(false);
        setLoading(true);
        await api.removeproductTypes({ProductTypeIds: deletingProductTypeIds });
        const response = await api.getproductTypes();
        setProductTypes(response.productType);
            setAlert({
            open: true,
            message: 'Product Type deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting product type:", error);
        setAlert({
            open: true,
            message: 'Failed to delete product type. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingProductTypeIds(null);
        setLoading(false);
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmationDialogOpen(false);
    };
      //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle reset filters / Export
    const handleResetFilters = () => {
        setSelectedProductTypeId('');
        setSearchNameEn('');
        setSearchNameAr('');
        setCurrentPage(1); 
    };
    const handleExport = () => {
        const selectedproductTypes = productTypes.filter((productType) => productType.selected);
        if (selectedproductTypes.length === 0) {
            console.log('No selected Product types to export');
            return;
        }
    
        // Prepare data for export
        const dataForExport = selectedproductTypes.map((producttype) => ({
        'Product Type ID': producttype.id,
        'Product Types in English': producttype.name_en,
        'Product Types in Arabic': producttype.name_ar,
        'Created At': formatDate(producttype.created_at),
        'Updated At': formatDate(producttype.updated_at),
        }));
        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'productTypes');
        XLSX.writeFile(wb, 'productTypes.xlsx');
    };
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // Edit existing order
   const handleEditClick = (productType) => {
    setSelectedProductTypeData(productType);
    setEditDialogOpen(true);
};
const handleEditSubmit = async (editedData) => {
    try {
    setLoading(true);

    const response = await api.updateProductType(editedData);
    console.log('API response:', response);

    const Response = await api.getProductTypes();
    setProductTypes(Response.productType);

    setAlert({
        open: true,
        message: 'Product Type updated successfully!',
        severity: 'success',
    });
    } catch (error) {
    console.error('Error submitting edited data:', error);
    setAlert({
        open: true,
        message: 'Failed to update product type. Please try again.',
        severity: 'error',
    });
    } finally {
    setSelectedProductTypeData(null);
    setLoading(false);
    }
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Add new order
const handleAddProductTypeClick = () => {
    setAddNewProductTypeDialogOpen(true);
};

const handleAddproductTypeubmit = async (data) => {
    try {
        setLoading(true);
        await api.addProductType(data);
        const response = await api.getProductTypes();
        setProductTypes(response.productType);

        setAlert({
            open: true,
            message: 'Product type Added successfully!',
            severity: 'success',
        });

        setAddNewProductTypeDialogOpen(false);
    } catch (error) {
        setAlert({
            open: true,
            message: 'Failed to update product type. Please try again.',
            severity: 'error',
        });
    } finally {
        setLoading(false);
    }
};    

    return (
        <div className="container">
            <div className="filter-section">
                <div className="filter-set">
                    <FormControl className="filter-form-control">
                    <InputLabel>Product Type Id</InputLabel>
                    <Select value={selectedProductTypeId || ''} onChange={handleProductTypeIdChange}>
                        <MenuItem value="" disabled>Select Product Type ID</MenuItem>
                        {productTypes.map((producttype) => (
                        <MenuItem key={producttype.id} value={producttype.id}>
                            {producttype.id}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>      

                    <FormControl className="filter-form-control">
                        <Input
                            value={searchNameEn}
                            onChange={handleNameEnChange}
                            placeholder ="Name English"
                        />
                    </FormControl>  

                    <FormControl className="filter-form-control">
                        <Input
                            value={searchNameAr}
                            onChange={handleNameArChange}
                            placeholder ="Name Arabic"
                        />
                    </FormControl>    
                </div>
            </div>

            <div className="actions-section">
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddProductTypeClick}
                >
                    <AddIcon /> Add New Product type
                </Button>
                </Box>
                <div className="actions-container">
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                    >
                        <DeleteIcon /> Delete
                    </Button>
                    </Box>

                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleExport}
                    >
                        <SaveAltIcon /> Export
                    </Button>
                    </Box>
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleResetFilters}
                    >
                        <RefreshIcon /> Reset filters
                    </Button>
                    </Box>
                </div>  
            </div>
            
            {loading ? (
            <div className="item">
                <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
            </div>
            ) : (
            <table className="lookup-table">
                <thead>
                    <tr>
                    <th>
                        <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectAll}
                        />
                    </th>
                    <th>Product Type ID</th>
                    <th>Name in English</th>
                    <th>Name in Arabic</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>  
                    </tr>
                </thead>
                <tbody>
                    {filteredProductType.map((producttype) => (
                    <tr key={producttype.id}>
                        <td>
                        <input type="checkbox"
                        checked={producttype.selected || false}
                        onChange={() => handleCheckboxChange(producttype.id)} 
                        />
                        </td>
                        <td>{producttype.id}</td>
                        <td>{producttype.name_en}</td>
                        <td>{producttype.name_ar}</td>
                        <td>{formatDate(producttype.created_at)}</td>
                        <td>{formatDate(producttype.updated_at)}</td>
                        <td> <IconButton size="small" onClick={() => handleEditClick(producttype)} ><EditIcon /></IconButton>
                            <IconButton size="small" onClick={() => handleDeleteSingle(producttype.id)} ><DeleteIcon /></IconButton>
                    </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            )}
            <Pagination
                currentPage={currentPage}
                paginate={paginate}
                totalItems={filteredProductType.length}
                itemsPerPage={productTypePerPage}
                prevButtonLabel="< Previous"
                nextButtonLabel="Next >"
                buttonColor="primary"
                activeButtonColor="contained"
                inactiveButtonColor="default"
                disabled={false}
            />
            <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
                <DialogContent className="dialog-content">
                    <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this product type?</Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button onClick={handleDeleteCancelSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                    Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmationSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isDeleteConfirmationDialogOpen} onClose={handleDeleteCancel}>
                <DialogContent className="dialog-content">
                    <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete the selected product types?</Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button onClick={handleDeleteCancel} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                    Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={alert.open} autoHideDuration={1000} onClose={handleCloseAlert}>
                <MuiAlert
                onClose={handleCloseAlert}
                severity={alert.severity}
                sx={{ width: '100%', fontSize: '1.5rem' }}
                >
                {alert.message}
                </MuiAlert>
            </Snackbar>
            

            <EditProductTypeDialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)} productType={selectedProductTypeData} onSubmit={handleEditSubmit}/>
            <AddProductTypeDialog isOpen={addProductTypeDialogOpen} onClose={() => setAddNewProductTypeDialogOpen(false)} onSubmit={handleAddproductTypeubmit}/>
        </div>
    );
};

export default ProductType;
