import React, { useState, useEffect } from "react";
import api from "state/api";
import "react-datepicker/dist/react-datepicker.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-js-loader";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as XLSX from "xlsx";
import Pagination from "components/pagination";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import { Input } from "@trendmicro/react-form-control";
import "./shippingAddress.css";
import DatePicker from "react-datepicker";

const ShippingAddresses = () => {
  // State variables
  const [users, setUsers] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [shippingAddressPerPage] = useState(100);
  /// filers
  const [selectedshippingAddressId, setSelectedshippingAddressId] =
    useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  ////////////////////////////////////////////////////////////
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // Single Delete
  const [deletingshippingAddressId, setDeletingshippingAddressId] =
    useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // Multi Delete
  const [deletingshippingAddressIds, setDeletingshippingAddressIds] = useState(
    []
  );
  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  // Edit shippingAddress data Dialog
  const [selectedshippingAddressData, setSelectedshippingAddressData] =
    useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [selectedCount, setSelectedCount] = useState(0);


  useEffect(() => {
    const fetchShippingAddresses = async () => {
      try {
        setLoading(true);
        const response = await api.getShippingAddresses();

        setShippingAddresses(response.shippingAddress);
        setUsers(response.users);
        setSelectAll(false);
      } catch (error) {
        console.error("Error fetching shippingAddress:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchShippingAddresses();
  }, [currentPage]);

  // Select all + Pagination
  const filteredShippingAddresses = shippingAddresses.filter(
    (shippingAddress) => {
      const userFilter =
        selectedUserId === null
          ? shippingAddress.user_id === null
          : !selectedUserId ||
            shippingAddress.user_id?.toString().includes(selectedUserId);
      const shippingAddressFilter =
        !selectedshippingAddressId ||
        shippingAddress.id.toString().includes(selectedshippingAddressId);

      const fromDateFilter =
        !fromDate || new Date(shippingAddress.created_at) >= new Date(fromDate);
      const toDateFilter =
        !toDate || new Date(shippingAddress.created_at) <= new Date(toDate);

      return (
        userFilter && shippingAddressFilter && fromDateFilter && toDateFilter
      );
    }
  );

  // Calculate the range of users to display based on pagination
  const indexOfLastshippingAddress = currentPage * shippingAddressPerPage;
  const indexOfFirstshippingAddress =
    indexOfLastshippingAddress - shippingAddressPerPage;
  const currentshippingAddress = filteredShippingAddresses.slice(
    indexOfFirstshippingAddress,
    indexOfLastshippingAddress
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredShippingAddresses.length / shippingAddressPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  // Handle checkbox change for individual users
  const handleCheckboxChange = (shippingAddressId) => {
    const updatedShippingAddresses = shippingAddresses.map((shippingAddress) =>
      shippingAddress.id === shippingAddressId
        ? { ...shippingAddress, selected: !shippingAddress.selected }
        : shippingAddress
    );
    setShippingAddresses(updatedShippingAddresses);
    const selectedCount = updatedShippingAddresses.filter(
      (shippingAddress) => shippingAddress.selected
    ).length;
    setSelectedCount(selectedCount);
    console.log(selectedCount);
  };

  // Handle checkbox change for all shippingAddress

  const handleSelectAll = () => {
    const updatedShippingAddresses = shippingAddresses.map((shippingAddress) =>
      currentshippingAddress.some(
        (currentCart) => currentCart.id === shippingAddress.id
      )
        ? { ...shippingAddress, selected: !selectAll }
        : shippingAddress
    );
    setShippingAddresses(updatedShippingAddresses);
    setSelectAll(!selectAll);
    const selectedCount = updatedShippingAddresses.filter(
      (shippingAddress) => shippingAddress.selected
    ).length;
    setSelectedCount(selectedCount);
  };

  // Format updated and created at data
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Africa/Cairo",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  /////////////////////////////////////////////////////////  Filters   ////////////////////////////////////////////////////////////////////

  const handleUserIdChange = (e) => {
    const value = e.target.value;

    if (value === "guest") {
      setSelectedUserId(null);
      setCurrentPage(1);
    } else {
      setSelectedUserId(value);
    }
  };

  const handleshippingAddressIdChange = (e) => {
    setSelectedshippingAddressId(e.target.value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  // Alert
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle single delete
  const handleDeleteSingle = (shippingAddressId) => {
    setDeletingshippingAddressId(shippingAddressId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmationSingle = async () => {
    try {
      setLoading(true);
      setDeleteDialogOpen(false);
      await api.removeShippingAddress(deletingshippingAddressId);
      const response = await api.getShippingAddresses();
      setShippingAddresses(response.shippingAddress);
      setAlert({
        open: true,
        message: "Shipping Address deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting Shipping Address:", error);
      setAlert({
        open: true,
        message: "Failed to delete Shipping Address. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingshippingAddressId(null);
      setLoading(false);
    }
  };
  const handleDeleteCancelSingle = () => {
    setDeleteDialogOpen(false);
    setDeletingshippingAddressId(null);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle delete from select
  const handleDelete = () => {
    const selectedshippingAddressIds = shippingAddresses
      .filter((shippingAddress) => shippingAddress.selected)
      .map((shippingAddress) => shippingAddress.id);
    if (selectedshippingAddressIds.length === 0) {
      return;
    }
    setDeletingshippingAddressIds(selectedshippingAddressIds);
    setDeleteConfirmationDialogOpen(true);
  };
  const handleDeleteConfirmation = async () => {
    try {
      setDeleteConfirmationDialogOpen(false);
      setLoading(true);
      await api.removeShippingAddresses({
        ShippingAddressesIds: deletingshippingAddressIds,
      });
      const response = await api.getShippingAddresses();
      setShippingAddresses(response.shippingAddress);
      setAlert({
        open: true,
        message: "Shipping Address deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting Shipping Address:", error);
      setAlert({
        open: true,
        message: "Failed to delete Shipping Address. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingshippingAddressIds(null);
      setLoading(false);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteConfirmationDialogOpen(false);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle reset filters / Export
  const handleResetFilters = () => {
    setSelectedshippingAddressId("");
    setSelectedUserId("");
    setFromDate(null);
    setToDate(null);
    setCurrentPage(1);
  };
  const handleExport = () => {
    const selectedshippingAddress = shippingAddresses.filter(
      (shippingAddress) => shippingAddress.selected
    );

    const columnNamesMapping = {
      id: "Shipping Address ID",
      user: "User",
      first_name: "First Name",
      last_name: "First Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      country: "Country",
      city: "City",
      appartment: "Appartment",
      state: "State",
      postal_code: "Postal Code",
      created_at: "Created At",
      updated_at: "Updated At",
    };

    const excelData = selectedshippingAddress.map((shippingAddress) => {
      const user = users.find((u) => u.id === shippingAddress.user_id);


      const mappedData = {
        id: shippingAddress.id,
        user: user
          ? `${user.first_name} ${user.last_name} - ${user.phone}`
          : "N/A",
        first_name: shippingAddress.first_name,
        last_name: shippingAddress.last_name,
        email: shippingAddress.email,
        phone: shippingAddress.phone,
        address: shippingAddress.address,
        country: shippingAddress.country,
        city: shippingAddress.city,
        appartment: shippingAddress.appartment,
        state: shippingAddress.state,
        postal_code: shippingAddress.postal_code,
        created_at: shippingAddress.created_at,
        updated_at: shippingAddress.updated_at,
      };

      // Map the column names for readability
      const mappedshippingAddress = {};
      Object.keys(mappedData).forEach((key) => {
        mappedshippingAddress[columnNamesMapping[key]] = mappedData[key];
      });

      return mappedshippingAddress;
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ShippingAddresses");
    XLSX.writeFile(wb, "selected_shippingAddress.xlsx");
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Edit existing shippingAddress
  const handleEditClick = (shippingAddress) => {
    setSelectedshippingAddressData(shippingAddress);
    setEditDialogOpen(true);
  };


  return (
    <div className="container">
      <div className="filter-section">
        <div className="filter-set">
          <FormControl className="filter-form-control">
            <InputLabel>shippingAddress ID</InputLabel>
            <Select
              value={selectedshippingAddressId}
              onChange={handleshippingAddressIdChange}
            >
              <MenuItem value="" disabled>
                Select shippingAddress ID
              </MenuItem>
              {shippingAddresses.map((shippingAddress) => (
                <MenuItem key={shippingAddress.id} value={shippingAddress.id}>
                  {shippingAddress.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <InputLabel>User</InputLabel>
            <Select value={selectedUserId || ""} onChange={handleUserIdChange}>
              <MenuItem value="" disabled>
                Select User ID
              </MenuItem>
              <MenuItem value="guest">Guest</MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.first_name} {user.last_name} - {user.phone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>
        <div className="filter-set">

          <FormControl className="filter-form-control">
            <DatePicker
              className="custom-datepicker"
              showIcon
              label="From Date"
              selected={fromDate}
              onChange={handleFromDateChange}
              dateFormat="dd/MM/yyyy"
              icon="fa fa-calendar"
              placeholderText="Select From Date"
            />
          </FormControl>

          <FormControl className="filter-form-control">
            <DatePicker
              className="custom-datepicker"
              showIcon
              label="To Date"
              selected={toDate}
              onChange={handleToDateChange}
              dateFormat="dd/MM/yyyy"
              icon="fa fa-calendar"
              placeholderText="Select To Date"
            />
          </FormControl>
        </div>
      </div>


      <div className="actions-section">
        <Box
          mb="1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        ></Box>
        <div className="actions-container">
          <Box
            mb="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <DeleteIcon /> Delete
            </Button>
          </Box>

          <Box
            mb="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleExport}
            >
              <SaveAltIcon /> Export
            </Button>
          </Box>
          <Box
            mb="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetFilters}
            >
              <RefreshIcon /> Reset filters
            </Button>
          </Box>
        </div>
      </div>

      {loading ? (
        <div className="item">
          <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
      ) : (
        <table className="lookup-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectAll}
                />
              </th>
              <th>Shipping Address ID</th>
              <th>User</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>News offers flag</th>
              <th>Default user flag</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Country</th>
              <th>City</th>
              <th>Appartment</th>
              <th>State</th>
              <th>Postal code</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredShippingAddresses.map((cart) => (
              <tr key={cart.id}>
                <td>
                  <input type="checkbox"
                    checked={cart.selected || false}
                    onChange={() => handleCheckboxChange(cart.id)} 
                />
                </td>
                <td>{cart.id}</td>
                <td>
                {users.map((user) => {
                  if (user.id === cart.user_id) {
                    return (
                      <div key={user.id}>
                        {user.first_name} {user.last_name} - {user.phone}
                      </div>
                    );
                  }
                  return null; 
                })}
                </td>

                <td>{cart.first_name}</td>
                <td>{cart.last_name}</td>
                <td>{cart.email}</td>  
                <td>{cart.news_offers_flag === 1 ? 'Yes' : 'No'}</td>            
                <td>{cart.default_user_flag === 1 ? 'Yes' : 'No'}</td>     
                <td>{cart.phone}</td>
                <td>{cart.address}</td>
                <td>{cart.country}</td>
                <td>{cart.city}</td>
                <td>{cart.appartment}</td>
                <td>{cart.state}</td>
                <td>{cart.postal_code}</td>
              <td>{formatDate(cart.created_at)}</td>
              <td>{formatDate(cart.updated_at)}</td>
                <td> <IconButton size="small" onClick={() => handleEditClick(cart)} ><EditIcon /></IconButton>
                    <IconButton size="small" onClick={() => handleDeleteSingle(cart.id)}><DeleteIcon /></IconButton>

              </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        currentPage={currentPage}
        paginate={paginate}
        totalItems={filteredShippingAddresses.length}
        itemsPerPage={shippingAddressPerPage}
        prevButtonLabel="< Previous"
        nextButtonLabel="Next >"
        buttonColor="primary"
        activeButtonColor="contained"
        inactiveButtonColor="default"
        disabled={false}
      />

      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
        <DialogContent className="dialog-content">
          <Typography style={{ fontSize: "1.2rem" }}>
            Are you sure you want to delete this shippingAddress?
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={handleDeleteCancelSingle}
            variant="outlined"
            style={{
              color: "#ffd700",
              bshippingAddressColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmationSingle}
            variant="outlined"
            style={{
              color: "#ffd700",
              bshippingAddressColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteConfirmationDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogContent className="dialog-content">
          <Typography style={{ fontSize: "1.2rem" }}>
            Are you sure you want to delete the selected shippingAddress?
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            style={{
              color: "#ffd700",
              bshippingAddressColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            variant="outlined"
            style={{
              color: "#ffd700",
              bshippingAddressColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.open}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <MuiAlert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%", fontSize: "1.5rem" }}
        >
          {alert.message}
        </MuiAlert>
      </Snackbar>

    </div>
  );
};

export default ShippingAddresses;
