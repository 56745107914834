import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    TextField,
} from '@mui/material';

const initialUserData = {
    first_name: '',
    last_name: '',
    email: '',
    birthday: '',
    phone: '',
    about_us: '',
    occupation: '',
    points: '',
    role: 'authenticated',
    password: '', 
    confirmPassword: '', 
};

const boldTypographyStyle = { fontSize: '16px', fontWeight: 'bold' };

const AddUserDialog = ({ isOpen, onClose, onSubmit }) => {
    const [newUserData, setNewUserData] = useState(initialUserData);
    const [validationErrors, setValidationErrors] = useState({});

useEffect(() => {
        if (isOpen) {
        setNewUserData(initialUserData);
        }
    }, [isOpen]);

const handleChange = (name, value) => {
    setNewUserData((prevData) => ({ ...prevData, [name]: value }));
};

const validateFields = () => {
    const errors = {};
    if (!newUserData.first_name) {
        errors.first_name = 'First Name is required';
    }

    if (!newUserData.last_name) {
        errors.last_name = 'Last Name is required';
    }

    if (!newUserData.email) {
        errors.email = 'Email is required';
    }
    if (!newUserData.phone) {
        errors.phone = 'Phone is required';
    }
    if (!newUserData.about_us) {
        errors.about_us = 'About us is required';
    }

    if (!newUserData.role) {
        errors.role = 'Role is required';
    }
    if (!/^\d{4}-\d{2}-\d{2}$/.test(newUserData.birthday)) {
        errors.birthday = 'Invalid date format. Please use YYYY-MM-DD';
    }
    if (!newUserData.password) {
        errors.password = 'Password is required';
    } else if (newUserData.password.length < 8) {
        errors.password = 'Password must be at least 8 characters long';
    } else if (newUserData.confirmPassword !== newUserData.password) {
        errors.confirmPassword = 'Passwords do not match';
    }
    
    if (!newUserData.confirmPassword) {
        errors.confirmPassword = 'Re-enter Password is required';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
};

const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? '*' : '';
};

const handleSubmit = () => {
    if (validateFields()) {
        onSubmit(newUserData);
    }
};

return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <form onSubmit={handleSubmit}> 
        <DialogContent dividers>
            <Box>
            <Typography variant="h6" gutterBottom style={boldTypographyStyle}>
                Basic Information
            </Typography>
            <TextField
                label={`First Name ${renderAsterisk('first_name')}`}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('first_name', e.target.value)}
                error={!!validationErrors.first_name}
                helperText={validationErrors.first_name}
            />
            <TextField
            label={`Last Name ${renderAsterisk('last_name')}`}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('last_name', e.target.value)}
            error={!!validationErrors.last_name}
            helperText={validationErrors.last_name}
            />
            <TextField
            label={`Email ${renderAsterisk('email')}`}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('email', e.target.value)}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
            autoComplete="new-password"
            />
            <TextField
            label={`Birthday ${renderAsterisk('birthday')}`}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('birthday', e.target.value)}
            error={!!validationErrors.birthday}
            helperText={
                validationErrors.birthday ||
                'Enter date in YYYY-MM-DD format'
            }
            />
            <TextField
            label={`Phone ${renderAsterisk('phone')}`}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('phone', e.target.value)}
            error={!!validationErrors.phone}
            helperText={validationErrors.phone}
            />
            <TextField
            label={`About Us ${renderAsterisk('about_us')}`}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('about_us', e.target.value)}
            error={!!validationErrors.about_us}
            helperText={validationErrors.about_us}
            />
            <TextField
            label={`Occupation`}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => handleChange('occupation', e.target.value)}
            />

            <TextField
                label={`Password ${renderAsterisk('password')}`}
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('password', e.target.value)}
                error={!!validationErrors.password}
                helperText={validationErrors.password}
                autoComplete="new-password"
            />

            <TextField
                label={`Re-enter Password ${renderAsterisk('confirmPassword')}`}
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('confirmPassword', e.target.value)}
                error={!!validationErrors.confirmPassword}
                helperText={validationErrors.confirmPassword}
                autoComplete="new-password"
            />

            <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.role)}>
                <InputLabel>Role</InputLabel>
                <Select
                value={newUserData.role || 'authenticated'}
                onChange={(e) => handleChange('role', e.target.value)}
                label="Select Is role"
                >
                <MenuItem value="authenticated">Authenticated</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                </Select>

                <FormHelperText variant="caption" error>
                {validationErrors.role}
                </FormHelperText>
            </FormControl>
            </Box>
        </DialogContent>
        </form>
        <DialogActions>
            <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
            Cancel
            </Button>
            <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
            Save Changes
            </Button>
        </DialogActions>
    </Dialog>
  );
};

AddUserDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddUserDialog;
