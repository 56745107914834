import { Box, Button, useTheme } from "@mui/material";
import React from 'react';

const Pagination = ({
  currentPage,
  paginate,
  totalItems,
  itemsPerPage,
  prevButtonLabel = '< Previous',
  nextButtonLabel = 'Next >',
  buttonColor = 'primary',
  activeButtonColor = 'contained',
  inactiveButtonColor = 'default',
  disabled = false,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const theme = useTheme();

  const buttonStyle = {
    marginRight: 1,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.text.primary,
    },
    color: theme.palette.text.primary,
  };

  return (
    <Box mt={3} display="flex" justifyContent="center">
      <Button
        onClick={() => paginate(currentPage - 1)}
        variant="outlined"
        color={buttonColor}
        disabled={currentPage === 1 || disabled}
        sx={buttonStyle}
      >
        {prevButtonLabel}
      </Button>
      {pageNumbers.map((number) => (
        <Button
          key={number}
          onClick={() => paginate(number)}
          variant={currentPage === number ? activeButtonColor : inactiveButtonColor}
          color={buttonColor}
          disabled={currentPage === number || disabled}
          sx={buttonStyle}
        >
          {number}
        </Button>
      ))}
      <Button
        onClick={() => paginate(currentPage + 1)}
        variant="outlined"
        color={buttonColor}
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage) || disabled}
        sx={buttonStyle}
      >
        {nextButtonLabel}
      </Button>
    </Box>
  );
};

export default Pagination;
