import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditReviewDialog = ({ isOpen, onClose, reviewData, onSubmit }) => {
  const [editedData, setEditedData] = useState({
    id: '',
    title: '',
    review_comment: '',
    rating: '',
    created_at: '',
    updated_at: '',
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const defaultValues = {
      id: '',
      title: '',
      review_comment: '',
      rating: '',
      created_at: '',
      updated_at: '',
    };

    setEditedData(reviewData || defaultValues);
  }, [reviewData]);

  const handleChange = (name, value) => {
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateFields = () => {
    const errors = {};

    if (!editedData.title) {
      errors.title = 'Title is required';
    }

    if (!editedData.review_comment) {
      errors.review_comment = 'Review comment is required';
    }

    if (!editedData.rating) {
      errors.rating = 'Rating is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? '*' : '';
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(editedData);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers>
        {editedData && (
          <Box>
            <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Basic Information
            </Typography>

            <TextField
              label={`Review ID ${renderAsterisk('id')}`}
              value={editedData.id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`User ID ${renderAsterisk('user_id')}`}
              value={editedData.user_id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Product ID ${renderAsterisk('product_id')}`}
              value={editedData.product_id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Title ${renderAsterisk('title')}`}
              value={editedData.title || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('title', e.target.value)}
              error={!!validationErrors.title}
              helperText={validationErrors.title}
            />
            <TextField
              label={`Review Comment ${renderAsterisk('review_comment')}`}
              value={editedData.review_comment || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('review_comment', e.target.value)}
              error={!!validationErrors.review_comment}
              helperText={validationErrors.review_comment}
            />
              <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.role)}>
              <InputLabel>Rating</InputLabel>
                <Select
                    value={editedData.rating }
                    onChange={(e) => handleChange('rating', e.target.value)}
                    label="Select Is rating"
                  >
                    <MenuItem value="1">⭐️</MenuItem>
                    <MenuItem value="2">⭐️⭐️</MenuItem>
                    <MenuItem value="3">⭐️⭐️⭐️</MenuItem>
                    <MenuItem value="4">⭐️⭐️⭐️⭐️</MenuItem>
                    <MenuItem value="5">⭐️⭐️⭐️⭐️⭐️</MenuItem>
                </Select>
                <Typography variant="body2" color="error">
                    {validationErrors.rating}
                </Typography>
            </FormControl>

          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditReviewDialog;
