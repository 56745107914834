import React, { useState, useEffect } from 'react';
import api from 'state/api';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "react-js-loader";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as XLSX from 'xlsx';
import EditReviewDialog from './editDialog';
import Pagination from 'components/pagination';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import  { Input } from '@trendmicro/react-form-control';

const Reviews = () => {
  // State variables
    const [reviews, setReviews] = useState([]);
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [reviewsPerPage] = useState(100);

    // filters
    const [selectedReviewId, setSelectedReviewId] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedProductId, setSelectedProductId] = useState('');
    const [searchTitle, setSearchTitle] = useState('');
    const [searchComment, setSearchComment] = useState('');
    const [searchRating, setSearchRating] = useState('');

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success', 
    });
    // Single Delete
    const [deletingReviewId, setDeletingReviewId] = useState('');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // Multi Delete
    const [deletingReviewIds, setDeletingReviewIds] = useState([]);
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

      // Edit review data Dialog
    const [selectedReviewData, setSelectedReviewData] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    useEffect(() => {
        const fetchReviews = async () => {
        try {
            setLoading(true);
            const response = await api.getReviews();
            setReviews(response.review);
            setUsers(response.users);
            setProducts(response.product);
            setSelectAll(false);
        } catch (error) {
            console.error('Error fetching Reviews:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchReviews();
    }, [currentPage]);
    const filteredReviews = reviews.filter((review) => {
        const reviewFilter = selectedReviewId ? review.id === selectedReviewId : true;
        const userIdFilter = selectedUserId ? review.user_id === selectedUserId : true;
        const productIdFilter = selectedProductId ? review.product_id === selectedProductId : true;
        const titleFilter = !searchTitle || review.title.toLowerCase().includes(searchTitle.toLowerCase());
        const ratingFilter = !searchRating || review.rating === parseInt(searchRating, 10);
        const commentFilter = !searchComment || review.review_comment.toLowerCase().includes(searchComment.toLowerCase());
        return (
            reviewFilter &&
            titleFilter &&
            ratingFilter &&
            commentFilter &&
            userIdFilter &&
            productIdFilter
        );
    });
      // Calculate the range of Reviews to display based on pagination
        const indexOfLastReview = currentPage * reviewsPerPage;
        const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
        const currentReviews = filteredReviews.slice(indexOfFirstReview, indexOfLastReview);
        
        const paginate = (pageNumber) => setCurrentPage(pageNumber);
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredReviews.length / reviewsPerPage); i++) {
            pageNumbers.push(i);
        }
      // Handle checkbox change for individual Review
    const handleCheckboxChange = (reviewId) => {
        const updatedReviews = reviews.map((review) =>
        review.id === reviewId ? { ...review, selected: !review.selected } : review
        );
        setReviews(updatedReviews);
    };
      // Handle checkbox change for all Reviews
    const handleSelectAll = () => {
        const updatedReviews = reviews.map((review) =>
            currentReviews.some((currentReview) => currentReview.id === review.id)
            ? { ...review, selected: !selectAll }
            : review
        );
        setReviews(updatedReviews);
        setSelectAll(!selectAll);
    };
      // Format updated and created at data
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Africa/Cairo',
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };
      /// Filters
    const handleReviewIdChange = (e) => {
        setSelectedReviewId(e.target.value);
        setCurrentPage(1);
    };
    const handleUserIdChange = (e) => {
        setSelectedUserId(e.target.value);
        setCurrentPage(1);
    };
    const handleProductIdChange = (e) => {
        setSelectedProductId(e.target.value);
        setCurrentPage(1);
    };
    const handleTitleChange = (e) => {
        setSearchTitle(e.target.value);
    };
    const handleCommentChange = (e) => {
        setSearchComment(e.target.value);
    };
    const handleRatingChange = (e) => {
        setSearchRating(e.target.value);
        setCurrentPage(1);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Alert
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setAlert({ ...alert, open: false });
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
// Handle single delete
    const handleDeleteSingle = (reviewId) => {
        setDeletingReviewId(reviewId);
        setDeleteDialogOpen(true);
    };
    
    const handleDeleteConfirmationSingle = async () => {
        try {
        setLoading(true);
        setDeleteDialogOpen(false);
        await api.removeReview(deletingReviewId);
        const response = await api.getReviews();
        setReviews(response.review);
        setAlert({
            open: true,
            message: 'Review deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting review:", error);
        setAlert({
            open: true,
            message: 'Failed to delete review. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingReviewId(null);
        setLoading(false);
        }
    };
    
    const handleDeleteCancelSingle = () => {
        setDeleteDialogOpen(false);
        setDeletingReviewId(null);
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  
  // Handle delete from select
    const handleDelete = () => {
        const selectedReviewIds = reviews.filter((review) => review.selected).map((review) => review.id);
        if (selectedReviewIds.length === 0) {
        console.log('No Reviews selected for deletion');
        return;
        }
        setDeletingReviewIds(selectedReviewIds);
        setDeleteConfirmationDialogOpen(true);
    };
    const handleDeleteConfirmation = async () => {
        try {
        setDeleteConfirmationDialogOpen(false);
        setLoading(true);
        await api.removeReviews({ reviewIds: deletingReviewIds });
        const response = await api.getReviews();
        setReviews(response.review);
        setAlert({
            open: true,
            message: 'Review deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting reviews:", error);
        setAlert({
            open: true,
            message: 'Failed to delete reviews. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingReviewIds(null);
        setLoading(false);
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmationDialogOpen(false);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle reset filters / Export
    const handleResetFilters = () => {
        setSelectedReviewId('');
        setSearchTitle('');
        setSearchRating('');
        setSearchComment('');
        setSelectedUserId('');
        setSelectedProductId('');
        setCurrentPage(1);
    };
    const handleExport = () => {
        const selectedReviews = filteredReviews.filter((review) => review.selected);
    
        if (selectedReviews.length === 0) {
        console.log('No selected reviews to export');
        return;
        }
    
        // Prepare data for export
        const dataForExport = selectedReviews.map((review) => ({
        'Review ID': review.id,
        'User ID': review.user_id,
        'Product ID': review.product_id,
        'Title': review.title,
        'Comment': review.review_comment,
        'Rating': review.rating,
        'Created At': formatDate(review.created_at),
        'Updated At': formatDate(review.updated_at),
        }));
    
        // Create a worksheet and set the data
        const ws = XLSX.utils.json_to_sheet(dataForExport);
    
        // Create a workbook with a single worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Reviews');
    
        // Save the file
        XLSX.writeFile(wb, 'reviews.xlsx');
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Edit existing user
    const handleEditClick = (data) => {
        setSelectedReviewData(data);
        setEditDialogOpen(true);
    };
    const handleEditSubmit = async (editedData) => {
        try {
        setLoading(true);
        await api.updateReview(editedData);
        const Response = await api.getReviews();
        setReviews(Response.review);
    
        setAlert({
            open: true,
            message: 'Review updated successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error('Error submitting edited data:', error);
        setAlert({
            open: true,
            message: 'Failed to update review. Please try again.',
            severity: 'error',
        });
        } finally {
        setSelectedReviewData(null);
        setLoading(false);
        }
    };
  

    return (
    <div className="container">
        <div className="filter-section">
            <div className="filter-set">
                <FormControl className="filter-form-control">
                    <InputLabel>Review Id</InputLabel>
                    <Select value={selectedReviewId || ''} onChange={handleReviewIdChange}>
                        <MenuItem value="" disabled>Select Review ID</MenuItem>
                        {reviews.map((review) => (
                        <MenuItem key={review.id} value={review.id}>
                            {review.id}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="filter-form-control">
                    <InputLabel>User Id</InputLabel>
                    <Select value={selectedUserId || ''} onChange={handleUserIdChange}>
                        <MenuItem value="" disabled>Select User ID</MenuItem>
                        {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.id}- {user.first_name} {user.last_name} - {user.phone}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="filter-form-control">
                    <InputLabel>Product Id</InputLabel>
                    <Select value={selectedProductId || ''} onChange={handleProductIdChange}>
                        <MenuItem value="" disabled>Select Product ID</MenuItem>
                        {products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                            {product.id}- {product.name_en} 
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="filter-form-control">
                    <InputLabel>Rating</InputLabel>
                    <Select value={searchRating} onChange={handleRatingChange}>
                    <MenuItem disabled> Select Rating</MenuItem>
                    <MenuItem value="1">⭐️</MenuItem>
                    <MenuItem value="2">⭐️⭐️</MenuItem>
                    <MenuItem value="3">⭐️⭐️⭐️</MenuItem>
                    <MenuItem value="4">⭐️⭐️⭐️⭐️</MenuItem>
                    <MenuItem value="5">⭐️⭐️⭐️⭐️⭐️</MenuItem>

                    </Select>
                </FormControl>

                <FormControl className="filter-form-control">
                <Input
                    value={searchTitle}
                    onChange={handleTitleChange}
                    placeholder ="Title"
                />
                </FormControl>

                <FormControl className="filter-form-control">
                <Input
                    value={searchComment}
                    onChange={handleCommentChange}
                    placeholder ="Comment"
                />
                </FormControl>
            </div>
        </div>

        <div className="actions-section">
                <div className="actions-container">
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                    >
                        <DeleteIcon /> Delete
                    </Button>
                    </Box>

                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleExport}
                    >
                        <SaveAltIcon /> Export
                    </Button>
                    </Box>
                    <Box     
                        mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    > 
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleResetFilters}
                    >
                        <RefreshIcon /> Reset filters
                    </Button>
                    </Box>
                </div>  
        </div>

        {loading ? (
        <div className="item">
            <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
        ) : (
        <table className="lookup-table">
            <thead>
            <tr>
                <th>
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectAll}
                />
                </th>
                <th>Review ID</th>
                <th>User ID</th>
                <th>Product ID</th>
                <th>Title</th>
                <th>Comment</th>
                <th>Rating</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {filteredReviews.map((review) => (
                <tr key={review.id}>
                <td>
                    <input
                    type="checkbox"
                    checked={review.selected || false}
                    onChange={() => handleCheckboxChange(review.id)}
                    />
                </td>
                <td>{review.id}</td>
                <td>
                    {users.map((user) => {
                    if (user.id === review.user_id) {
                        return (
                        <div key={user.id}>
                            {user.id}- {user.first_name} {user.last_name} 
                        </div>
                        );
                    }
                    return null; 
                    })}
                </td>
                <td>{products.map((product) => {
                    if (product.id === review.product_id) {
                        return (
                        <div key={product.id}>
                            {product.id}- {product.name_en}
                        </div>
                        );
                    }
                    return null; 
                    })}
                </td>
                <td>{review.title}</td>
                <td>{review.review_comment}</td>
                <td>
                {Array.from({ length: review.rating }, (_, index) => (
                    <span key={index}>⭐️</span>
                ))}
                </td> 
                <td>{formatDate(review.created_at)}</td>
                <td>{formatDate(review.updated_at)}</td>
                <td>
                    <IconButton size="small" onClick={() => handleEditClick(review)}>
                    <EditIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDeleteSingle(review.id)}>
                    <DeleteIcon />
                    </IconButton>
                </td>
                </tr>
            ))}
            </tbody>
        </table>
        )}
        <Pagination
                currentPage={currentPage}
                paginate={paginate}
                totalItems={filteredReviews.length}
                itemsPerPage={reviewsPerPage}
                prevButtonLabel="< Previous"
                nextButtonLabel="Next >"
                buttonColor="primary"
                activeButtonColor="contained"
                inactiveButtonColor="default"
                disabled={false}
            />

        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
            <DialogContent className="dialog-content">
                <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this review?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={handleDeleteCancelSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                Cancel
                </Button>
                <Button onClick={handleDeleteConfirmationSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                Delete
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={isDeleteConfirmationDialogOpen} onClose={handleDeleteCancel}>
            <DialogContent className="dialog-content">
                <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete the selected reviews?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={handleDeleteCancel} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                Delete
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={alert.open} autoHideDuration={1000} onClose={handleCloseAlert}>
            <MuiAlert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: '100%', fontSize: '1.5rem' }}
            >
            {alert.message}
            </MuiAlert>
        </Snackbar>

        <EditReviewDialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)} reviewData={selectedReviewData} onSubmit={handleEditSubmit}/>

    </div>
    );
};

export default Reviews;
