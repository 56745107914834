import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditFAQDialog = ({ isOpen, onClose, faqData, products, onSubmit }) => {
  const [editedData, setEditedData] = useState({
    id: '',
    product_id: '',
    question_en: '',
    question_ar: '',
    answer_en: '',
    answer_ar: '',
    created_at: '',
    updated_at: '',
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const defaultValues = {
      id: '',
      product_id: '',
      question_en: '',
      question_ar: '',
      answer_en: '',
      answer_ar: '',
      created_at: '',
      updated_at: '',
    };

    setEditedData(faqData || defaultValues);
  }, [faqData]);

  const handleChange = (name, value) => {
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateFields = () => {
    const errors = {};

    if (!editedData.product_id) {
      errors.product_id = 'Product ID is required';
    }

    if (!editedData.question_en) {
      errors.question_en = 'Question (English) is required';
    }

    if (!editedData.question_ar) {
      errors.question_ar = 'Question (Arabic) is required';
    }

    if (!editedData.answer_en) {
      errors.answer_en = 'Answer (English) is required';
    }

    if (!editedData.answer_ar) {
      errors.answer_ar = 'Answer (Arabic) is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? '*' : '';
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(editedData);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers>
        {editedData && (
          <Box>
            <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Basic Information
            </Typography>

            <TextField
              label={`FAQ ID ${renderAsterisk('id')}`}
              value={editedData.id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.product_id)}>
              <InputLabel>Product ID</InputLabel>
              <Select
                value={editedData.product_id}
                onChange={(e) => handleChange('product_id', e.target.value)}
                label="Select Product ID"
              >
                {products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.id}- {product.name_en}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" color="error">
                {validationErrors.product_id}
              </Typography>
            </FormControl>

            <TextField
              label={`Question (English) ${renderAsterisk('question_en')}`}
              value={editedData.question_en || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('question_en', e.target.value)}
              error={!!validationErrors.question_en}
              helperText={validationErrors.question_en}
            />

            <TextField
              label={`Question (Arabic) ${renderAsterisk('question_ar')}`}
              value={editedData.question_ar || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('question_ar', e.target.value)}
              error={!!validationErrors.question_ar}
              helperText={validationErrors.question_ar}
            />

            <TextField
              label={`Answer (English) ${renderAsterisk('answer_en')}`}
              value={editedData.answer_en || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('answer_en', e.target.value)}
              error={!!validationErrors.answer_en}
              helperText={validationErrors.answer_en}
            />

            <TextField
              label={`Answer (Arabic) ${renderAsterisk('answer_ar')}`}
              value={editedData.answer_ar || ''}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange('answer_ar', e.target.value)}
              error={!!validationErrors.answer_ar}
              helperText={validationErrors.answer_ar}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', fontSize: '16px', fontWeight: 'bold' }}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFAQDialog;
