import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [apiToken, setApiToken] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("api_token");
    if (storedToken) {
      checkToken(storedToken);
    } else {
      setAuthChecked(true);
    }
  }, []);

  async function checkToken(token) {
    console.log("Checking token...");

    try {
      const response = await axios.post(
        "https://dashboard.natindamiddleeast.com/api/checkToken",
        { api_token: token }
      );

      if (response.data && response.data.message === "Token is valid") {
        console.log(token);
        console.log("Token is valid");
        login(token);
      } else {
        console.warn("Token validation failed. Response data:", response.data);
        logout();
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);

        if (error.response.status === 400) {
          console.error("Token is invalid or missing");
        } else {
          console.error("Unexpected server error during token check");
        }
      } else if (error.request) {
        // Request was made but no response was received
        console.error("Request data:", error.request);
        console.error(
          "No response received from server. Possible network issue."
        );
      } else {
        // Something else happened
        console.error("Error message:", error.message);
        console.error("Unexpected error during token check");
      }
      logout();
    } finally {
      setAuthChecked(true);
    }
  }

  const login = (token) => {
    setIsAuthenticated(true);
    setApiToken(token);
    localStorage.setItem("api_token", token);
    setAuthChecked(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setApiToken(null);
    localStorage.removeItem("api_token");
    setAuthChecked(true);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, apiToken, authChecked }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
