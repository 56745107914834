import React, { useState, useEffect } from 'react';
import api from 'state/api';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "react-js-loader";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as XLSX from 'xlsx';
import Pagination from 'components/pagination';
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import EditBlogDialog from './editDialog';
import AddBlogDialog from './addDialog';
const Blogs = () => {
  // State variables
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(100);

    // filters
    const [selectedBlogId, setSelectedBlogId] = useState('');

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success', 
    });
    // Single Delete
    const [deletingBlog, setDeletingBlogId] = useState('');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // Multi Delete
    const [deletingBlogIds, setDeletingBlogIds] = useState([]);
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

      // Edit Blogs data Dialog
    const [selectedBlogData, setSelectedBlogData] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
        // Add new promo code Dialog
    const [addNewBlogDialogOpen, setAddNewBlogDialogOpen] = useState(false);

    useEffect(() => {
        const fetchBlogs = async () => {
        try {
            setLoading(true);
            const response = await api.getBlogs();
            setBlogs(response.blog);
            setSelectAll(false);
        } catch (error) {
            console.error('Error fetching Blogs:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchBlogs();
    }, [currentPage]);
    const filteredBlogs = blogs.filter((blog) => {
        const blogfilter = selectedBlogId ? blog.id === selectedBlogId : true;
        return (
            blogfilter
        );
    });
      // Calculate the range of Reviews to display based on pagination
        const indexOfLastBlogs = currentPage * blogsPerPage;
        const indexOfFirstBlogs = indexOfLastBlogs - blogsPerPage;
        const currentBlogs = filteredBlogs.slice(indexOfFirstBlogs, indexOfLastBlogs);
        
        const paginate = (pageNumber) => setCurrentPage(pageNumber);
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredBlogs.length / blogsPerPage); i++) {
            pageNumbers.push(i);
        }
      // Handle checkbox change for individual Blogs
    const handleCheckboxChange = (blogId) => {
        const updatedBlogs = blogs.map((blog) =>
        blog.id === blogId ? { ...blog, selected: !blog.selected } : blog
        );
        setBlogs(updatedBlogs);
    };
      // Handle checkbox change for all Blogs
    const handleSelectAll = () => {
        const updatedBlogs = blogs.map((blog) =>
        currentBlogs.some((currentBlog) => currentBlog.id === blog.id)
            ? { ...blog, selected: !selectAll }
            : blog
        );
        setBlogs(updatedBlogs);
        setSelectAll(!selectAll);
    };
      // Format updated and created at data
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Africa/Cairo',
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };
      /// Filters
    const handleBlogIdChange = (e) => {
        setSelectedBlogId(e.target.value);
        setCurrentPage(1);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Alert
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setAlert({ ...alert, open: false });
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
// Handle single delete
    const handleDeleteSingle = (blogId) => {
        setDeletingBlogId(blogId);
        setDeleteDialogOpen(true);
    };
    
    const handleDeleteConfirmationSingle = async () => {
        try {
        setLoading(true);
        setDeleteDialogOpen(false);
        await api.removeBlog(deletingBlog);
        const response = await api.getBlogs();
        setBlogs(response.blog);
        setAlert({
            open: true,
            message: 'Blog deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting Blog:", error);
        setAlert({
            open: true,
            message: 'Failed to delete Blogs. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingBlogId(null);
        setLoading(false);
        }
    };
    
    const handleDeleteCancelSingle = () => {
        setDeleteDialogOpen(false);
        setDeletingBlogId(null);
    };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  
  // Handle delete from select
    const handleDelete = () => {
        const selectedBlogsIds = blogs.filter((blog) => blog.selected).map((blog) => blog.id);
        if (selectedBlogsIds.length === 0) {
        console.log('No Blogs selected for deletion');
        return;
        }
        setDeletingBlogIds(selectedBlogsIds);
        setDeleteConfirmationDialogOpen(true);
    };
    const handleDeleteConfirmation = async () => {
        try {
        setDeleteConfirmationDialogOpen(false);
        setLoading(true);
        await api.removeBlogs({ blogsIds: deletingBlogIds });
        const response = await api.getBlogs();
        setBlogs(response.blog);
        setAlert({
            open: true,
            message: 'Blogs deleted successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error("Error deleting Blogs:", error);
        setAlert({
            open: true,
            message: 'Failed to delete Blogs. Please try again.',
            severity: 'error',
        });
        } finally {
        setDeletingBlogIds(null);
        setLoading(false);
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmationDialogOpen(false);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle reset filters / Export
    const handleResetFilters = () => {
        setSelectedBlogId('');
        setCurrentPage(1);
    };
    const handleExport = () => {
        const selectedBlogs = filteredBlogs.filter((blog) => blog.selected);
    
        if (selectedBlogs.length === 0) {
        console.log('No selected Blogs to export');
        return;
        }
    
        // Prepare data for export
        const dataForExport = selectedBlogs.map((blog) => ({
        'Review ID': blog.id,
        'Product ID': blog.product_id,
        'Question English': blog.question_en,
        'Question Arabic': blog.question_ar,
        'Answer in English': blog.answer_en,
        'Answer in Arabic': blog.answer_ar,
        'Created At': formatDate(blog.created_at),
        'Updated At': formatDate(blog.updated_at),
        }));
    
        // Create a worksheet and set the data
        const ws = XLSX.utils.json_to_sheet(dataForExport);
    
        // Create a workbook with a single worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Blogs');
    
        // Save the file
        XLSX.writeFile(wb, 'blogs.xlsx');
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Edit existing Blogs
    const handleEditClick = (data) => {
        setSelectedBlogData(data);
        console.log(selectedBlogData);
        setEditDialogOpen(true);
    };
    const handleEditSubmit = async (editedData) => {

        try {
        setLoading(true);
        console.log("editedData");
        console.log(editedData);
        // await api.updateBlog(editedData);
        const Response = await api.getBlogs();
        setBlogs(Response.blog);
    
        setAlert({
            open: true,
            message: 'Blogs updated successfully!',
            severity: 'success',
        });
        } catch (error) {
        console.error('Error submitting edited data:', error);
        setAlert({
            open: true,
            message: 'Failed to update Blogs. Please try again.',
            severity: 'error',
        });
        } finally {
        setSelectedBlogData(null);
        setLoading(false);
        }
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /// Add new Blogs
    const handleAddBlogsClick = () => {
        setAddNewBlogDialogOpen(true);
    };

    const handleAddBlogsSubmit = async (data) => {
        try {
            setLoading(true);
            await api.addBlog(data);
            const response = await api.getBlogs();
            setBlogs(response.blog);

            setAlert({
                open: true,
                message: 'Blogs Added successfully!',
                severity: 'success',
            });

            setAddNewBlogDialogOpen(false);
        } catch (error) {
            setAlert({
                open: true,
                message: 'Failed to update Blogs. Please try again.',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };   

    return (
        <div className="container">
        <div className="filter-section">
            <div className="filter-set">
                <FormControl className="filter-form-control">
                    <InputLabel>Blog Id</InputLabel>
                    <Select value={selectedBlogId || ''} onChange={handleBlogIdChange}>
                        <MenuItem value="" disabled>Select Blog ID</MenuItem>
                        {blogs.map((blog) => (
                        <MenuItem key={blog.id} value={blog.id}>
                            {blog.id}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </div>
        </div>
        <div className="actions-section">
            <Box     
                mb="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            > 
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddBlogsClick}
            >
                <AddIcon /> Add New Blog
            </Button>
            </Box>
            <div className="actions-container">
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                >
                    <DeleteIcon /> Delete
                </Button>
                </Box>

                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleExport}
                >
                    <SaveAltIcon /> Export
                </Button>
                </Box>
                <Box     
                    mb="1rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                > 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleResetFilters}
                >
                    <RefreshIcon /> Reset filters
                </Button>
                </Box>
            </div>  
        </div>
        {loading ? (
        <div className="item">
            <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
        ) : (
        <table className="lookup-table">
            <thead>
            <tr>
                <th>
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectAll}
                />
                </th>
                <th>Blog ID</th>
                <th>Title in English</th>
                <th>Title in Arabic</th>
                <th>Content in English</th>
                <th>Content in Arabic</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {filteredBlogs.map((blog) => (
                <tr key={blog.id}>
                <td>
                    <input
                    type="checkbox"
                    checked={blog.selected || false}
                    onChange={() => handleCheckboxChange(blog.id)}
                    />
                </td>
                <td>{blog.id}</td>
                <td>{blog.title_en}</td>
                <td>{blog.title_ar}</td>
                <td>{blog.content_en}</td>
                <td>{blog.content_ar}</td>             
                <td>{formatDate(blog.created_at)}</td>
                <td>{formatDate(blog.updated_at)}</td>
                <td>
                    <IconButton size="small" onClick={() => handleEditClick(blog)}>
                    <EditIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDeleteSingle(blog.id)}>
                    <DeleteIcon />
                    </IconButton>
                </td>
                </tr>
            ))}
            </tbody>
        </table>
        )}
        <Pagination
            currentPage={currentPage}
            paginate={paginate}
            totalItems={filteredBlogs.length}
            itemsPerPage={blogsPerPage}
            prevButtonLabel="< Previous"
            nextButtonLabel="Next >"
            buttonColor="primary"
            activeButtonColor="contained"
            inactiveButtonColor="default"
            disabled={false}
        />
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
            <DialogContent className="dialog-content">
                <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete this FAQ?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={handleDeleteCancelSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                Cancel
                </Button>
                <Button onClick={handleDeleteConfirmationSingle} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                Delete
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={isDeleteConfirmationDialogOpen} onClose={handleDeleteCancel}>
            <DialogContent className="dialog-content">
                <Typography style={{ fontSize: '1.2rem' }}>Are you sure you want to delete the selected FAQ?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={handleDeleteCancel} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700',fontSize: '16px',fontWeight: 'bold'  }}>
                Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} variant="outlined" style={{ color: '#ffd700', borderColor: '#ffd700' ,fontSize: '16px',fontWeight: 'bold' }}>
                Delete
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={alert.open} autoHideDuration={1000} onClose={handleCloseAlert}>
            <MuiAlert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: '100%', fontSize: '1.5rem' }}
            >
            {alert.message}
            </MuiAlert>
        </Snackbar>

        <EditBlogDialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)} blogData={selectedBlogData} onSubmit={handleEditSubmit}/>
        <AddBlogDialog isOpen={addNewBlogDialogOpen} onClose={() => setAddNewBlogDialogOpen(false)} onSubmit={handleAddBlogsSubmit}/>


    </div>
    );
};

export default Blogs;
