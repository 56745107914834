import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "./carts.css";

const EditCartDialog = ({
  isOpen,
  onClose,
  cartData,
  shippingAddress,
  user,
}) => {
  const [editedData, setEditedData] = useState({
    id: "",
    user_id: "",
    shipping_address_id: "",
    subtotal: "",
    promocode_id: "",
    points: "",
    total_price: "",
    product_quantity: [],
  });

  useEffect(() => {
    setEditedData(
      cartData || {
        id: "",
        user_id: "",
        shipping_address_id: "",
        subtotal: "",
        shipping_fees: "",
        promocode_id: "",
        points: "",
        total_price: "",
        product_quantity: [],
      }
    );
  }, [cartData]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers>
        {editedData && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>

            <TextField
              label="Order ID"
              value={editedData.id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <TextField
              label="User ID"
              value={`${user?.first_name || ""} ${user?.last_name || ""} - ${
                user?.phone || ""
              }`}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Shipping Address"
              value={`${shippingAddress?.first_name || ""} ${
                shippingAddress?.last_name || ""
              } - ${shippingAddress?.phone || ""}`}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <TextField
              label="Subtotal"
              value={editedData.subtotal || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Promocode ID"
              value={editedData.promocode_id || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Points"
              value={editedData.points || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Total Price"
              value={editedData.total_price || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <Typography variant="h6" gutterBottom>
              Products
            </Typography>
            {editedData.product_quantity.length > 0 ? (
              editedData.product_quantity.map((product, index) => (
                <div key={index} className="carts-products">
                  <span>{product.name_en}</span>
                  <span>Single Product Price: {product.price} SAR</span>
                  <span>Quantity: {product.pivot.quantity}</span>
                  <img
                    style={{ marginBottom: "20px" }}
                    src={product.medias[0].image_url}
                    alt={product.name_en}
                  />
                </div>
              ))
            ) : (
              <Typography variant="body1">No products in this cart.</Typography>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ color: "#ffd700", fontSize: "16px", fontWeight: "bold" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCartDialog;
