import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import api from "state/api";
import "./index.css";

const chartSetting = {
  yAxis: [
    {
      label: "Number of Carts Per Week",
    },
  ],
  width: 800,
  height: 600,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-10px, 0)",
    },
  },
};

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.getDashboard();
        setDashboardData(response);
      } catch (error) {
        console.log("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const transformCartsPerWeekData = (cartsPerWeek) => {
    return cartsPerWeek.map((weekData) => ({
      week_start_date: weekData.week_start_date,
      count: weekData.count,
    }));
  };

  return (
    <div className="dashboard-container">
      {loading ? (
        <div className="loader-container">
          <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
      ) : (
        dashboardData && (
          <div className="dashboard-grid">
            <div className="dashboard-card">
              <h3>Users</h3>
              <p>{dashboardData.user}</p>
            </div>
            <div className="dashboard-card">
              <h3>Carts</h3>
              <p>{dashboardData.cart}</p>
            </div>
            <div className="dashboard-card">
              <h3>Orders</h3>
              <p>{dashboardData.order}</p>
            </div>
            <div className="dashboard-card-mui">
              <h2>Product Quantities in Carts</h2>
              <h3>
                Total number of products across all carts:{" "}
                {dashboardData.totalProductQuantities}
              </h3>
              <PieChart
                series={[
                  {
                    data: dashboardData.productQuantities.map(
                      (product, index) => ({
                        id: index,
                        value: parseInt(product.total_quantity, 10),
                        label: product.name_en,
                        color: product.theme,
                      })
                    ),
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    label: {
                      visible: true,
                      position: "outside",
                    },
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                  },
                }}
                height={400}
              />
            </div>
            <div className="dashboard-card-mui">
              <h2>Number of Carts Over Time</h2>
              <BarChart
                dataset={transformCartsPerWeekData(dashboardData.cartsPerWeek)}
                xAxis={[{ scaleType: "band", dataKey: "week_start_date" }]}
                series={[{ dataKey: "count", label: "Count" }]}
                {...chartSetting}
              />
            </div>
          </div>
        )
      )}
      <p className="footer-text">
        Developed and operated by Omar Halim and Zeyad Salah.
      </p>
    </div>
  );
};

export default Dashboard;
