import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    TextField,
} from '@mui/material';

const initialProductTypeData = {
    name_en: '',
    name_ar: '',
};

const boldTypographyStyle = { fontSize: '16px', fontWeight: 'bold' };

const AddProductTypeDialog = ({ isOpen, onClose, onSubmit }) => {
    const [ProductType, setNewProductTypeData] = useState(initialProductTypeData);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (isOpen) {
        setNewProductTypeData(initialProductTypeData);
        }
    }, [isOpen]);

    const handleChange = (name, value) => {
        setNewProductTypeData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateFields = () => {  
        const errors = {};
    
        if (!ProductType.name_en) {
            errors.name_en = 'Name English is required';
        }
    
        if (!ProductType.name_ar) {
            errors.name_ar = 'Name Arabic is required';
        }
    
        setValidationErrors(errors);
    
        return Object.keys(errors).length === 0;
    };
    
    

    const renderAsterisk = (fieldName) => {
        return validationErrors[fieldName] ? '*' : '';
    };

    const handleSubmit = () => {
        if (validateFields()) {
        onSubmit(ProductType);
        }
    };


    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <form onSubmit={handleSubmit}>
            <DialogContent dividers>
            <Box>
                <Typography variant="h6" gutterBottom style={boldTypographyStyle}>
                Product Type Information
                </Typography>

                <TextField
                label={`Name English ${renderAsterisk('name_en')}`}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('name_en', e.target.value)}
                error={!!validationErrors.name_en}
                helperText={validationErrors.name_en}
                />

                <TextField
                label={`Name Arabic ${renderAsterisk('name_ar')}`}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => handleChange('name_ar', e.target.value)}
                error={!!validationErrors.name_ar}
                helperText={validationErrors.name_ar}
                />
            </Box>
            </DialogContent>
        </form>
        <DialogActions>
            <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
            Cancel
            </Button>
            <Button onClick={handleSubmit} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
            Save Changes
            </Button>
        </DialogActions>
        </Dialog>
    );
};

    AddProductTypeDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    };

export default AddProductTypeDialog;
