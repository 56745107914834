import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "./Login.css";

const API_URL = "https://dashboard.natindamiddleeast.com/api";

function Login() {
  const { isAuthenticated, login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}/loginAdmin`, {
        email: email,
        password: password,
      });

      const userData = response.data;
      console.log("Login successful:", userData);

      login(userData.api_token);
      localStorage.setItem("api_token", userData.api_token);
    } catch (error) {
      console.error("Error during login:", error);

      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);

        if (error.response.status === 401) {
          setError("Invalid email or password");
        } else {
          setError("An error occurred. Please try again later.");
        }
      } else if (error.request) {
        // Request was made but no response was received
        console.error("Request data:", error.request);
        setError(
          "No response from server. Please check your network connection."
        );
      } else {
        // Something else happened
        console.error("Error message:", error.message);
        setError("An unexpected error occurred. Please try again later.");
      }

      setPassword("");
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <h1 className="company-name">Natinda</h1>
        <form>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="form-control"
          />
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="form-control"
          />
          <button
            type="button"
            onClick={handleLogin}
            className="btn btn-primary btn-lg w-100"
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Login"
            )}
          </button>
          {error && <p className="text-danger mt-3">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Login;
