import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from "@mui/material";

const initialPromoCodeData = {
  code: "",
  value: "",
  is_working: "Working",
  max_amount: "",
  max_number_of_used: "",
  expiry_date: "",
};

const boldTypographyStyle = { fontSize: "16px", fontWeight: "bold" };

const AddPromoCodeDialog = ({ isOpen, onClose, onSubmit }) => {
  const [newPromoCodeData, setNewPromoCodeData] =
    useState(initialPromoCodeData);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      setNewPromoCodeData(initialPromoCodeData);
    }
  }, [isOpen]);

  const handleChange = (name, value) => {
    setNewPromoCodeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateFields = () => {
    const errors = {};

    if (!newPromoCodeData.code) {
      errors.code = "Promo Code is required";
    }
    if (!newPromoCodeData.value) {
      errors.value = "Value is required";
    }
    if (!newPromoCodeData.max_amount) {
      errors.max_amount = "Max Amount is required";
    }
    if (!newPromoCodeData.max_number_of_used) {
      errors.max_number_of_used = "Number of Uses Left is required";
    }
    if (!newPromoCodeData.is_working) {
      errors.is_working = "Working status is required";
    }

    if (!/^\d{4}-\d{2}-\d{2}$/.test(newPromoCodeData.expiry_date)) {
      errors.expiry_date = "Invalid date format. Please use YYYY-MM-DD";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? "*" : "";
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(newPromoCodeData);
    }
    console.log("newPromoCodeData",newPromoCodeData)
  };

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomCode = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomCode += characters.charAt(randomIndex);
    }
    handleChange("code", randomCode);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Box>
            <Typography variant="h6" gutterBottom style={boldTypographyStyle}>
              Promo Code Information
            </Typography>

            <TextField
              label={
                newPromoCodeData.code
                  ? `Promo Code ${renderAsterisk("code")}`
                  : "Promo Code"
              }
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPromoCodeData.code}
              onChange={(e) => handleChange("code", e.target.value)}
              error={!!validationErrors.code}
              helperText={validationErrors.code}
              InputLabelProps={{
                shrink: !!newPromoCodeData.code,
              }}
            />
            <Box
              mb="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 8 }}
                onClick={generateRandomCode}
              >
                Generate Promo Code
              </Button>
            </Box>

            <TextField
              label={`Value ${renderAsterisk("value")}`}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("value", e.target.value)}
              error={!!validationErrors.value}
              helperText={validationErrors.value}
            />

            <TextField
              label={`Amount deducted  ${renderAsterisk("max_amount")}`}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("max_amount", e.target.value)}
              error={!!validationErrors.value}
              helperText={validationErrors.value}
            />

            <TextField
              label={`Number of Uses Left ${renderAsterisk(
                "max_number_of_used"
              )}`}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                handleChange("max_number_of_used", e.target.value)
              }
              error={!!validationErrors.value}
              helperText={validationErrors.value}
            />
            <TextField
              label={`Expiry Date ${renderAsterisk("expiry_date")}`}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => handleChange("expiry_date", e.target.value)}
              error={!!validationErrors.expiry_date}
              helperText={
                validationErrors.expiry_date ||
                "Enter date in YYYY-MM-DD format"
              }
            />

            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(validationErrors.is_working)}
            >
              <InputLabel>Is Working? *</InputLabel>
              <Select
                value={newPromoCodeData.is_working}
                onChange={(e) => handleChange("is_working", e.target.value)}
                label="Select Is Working"
              >
                <MenuItem value="Working">Working</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
              </Select>
              <FormHelperText variant="caption" error>
                {validationErrors.is_working}
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ color: "#ffd700", ...boldTypographyStyle }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          style={{ color: "#ffd700", ...boldTypographyStyle }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddPromoCodeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddPromoCodeDialog;
