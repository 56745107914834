import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "../src/theme";
import Layout from "../src/scenes/layout";
import Dashboard from "../src/scenes/dashboard";
import Products from "scenes/products";
import Login from "scenes/login";
import Orders from "scenes/orders";
import Users from "scenes/users";
import PromoCodes from "scenes/promocodes";
import Reviews from "scenes/reviews";
import FAQ from "scenes/faq";
import Blogs from "scenes/blogs";
import ProductType from "scenes/producttype";
import Receipt from "scenes/receipt";
import Carts from "scenes/carts";
import ShippingAddresses from "scenes/shippingaddresses";
import { useAuth } from "AuthContext";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const { isAuthenticated, authChecked } = useAuth();

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              element={
                isAuthenticated ? <Layout /> : <Navigate to="/login" replace />
              }
            >
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/products" element={<Products />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/promo-codes" element={<PromoCodes />} />
              <Route path="/users" element={<Users />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/product-type" element={<ProductType />} />
              <Route path="/receipt/:id" element={<Receipt />} />
              <Route path="/carts" element={<Carts />} />
              <Route path="/carts/:cartId" element={<Carts />} />
              <Route
                path="/shipping-addresses"
                element={<ShippingAddresses />}
              />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
