import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AWS from "aws-sdk";
import Typography from '@mui/material/Typography';
import { HashLoader} from 'react-spinners';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    TextField,
} from '@mui/material';

const initialBlogData = {
    title_en: '',
    title_ar: '',
    content_en: '',
    content_ar: '',
    hidden: 1, 
};

const boldTypographyStyle = { fontSize: '16px', fontWeight: 'bold' };

const AddBlogDialog = ({ isOpen, onClose, onSubmit }) => {
    const [blog, setNewBlogData] = useState(initialBlogData);
    const [validationErrors, setValidationErrors] = useState({});
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (isOpen) {
            setNewBlogData(initialBlogData);
        }
    }, [isOpen]);

    const handleChange = (name, value) => {
        setNewBlogData((prevData) => ({ ...prevData, [name]: value }));
    };


    const validateFields = () => {
        const errors = {};

        if (!blog.title_en) {
            errors.title_en = 'Title English is required';
        }

        if (!blog.title_ar) {
            errors.title_ar = 'Title Arabic is required';
        }

        if (!blog.content_en) {
            errors.content_en = 'Content English is required';
        }

        if (!blog.content_ar) {
            errors.content_ar = 'Content Arabic is required';
        }

        if (!blog.hidden) {
            errors.hidden = 'Select Is hidden or live';
        }

        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        if (validateFields()) {
          setIsLoading(true); 

      
        }
    };

    const renderAsterisk = (fieldName) => {
        return validationErrors[fieldName] ? '*' : '';
    };
  //////Product Img
  function selectFiles() {
    fileInputRef.current.click();
  }
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    // Filter out only image files (you can customize the accepted file types)
    const imageFiles = Array.from(selectedFiles).filter((file) =>
      file.type.startsWith("image/")
    );

    // Map the selected files to an array of image objects
    const newImages = Array.from(imageFiles).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      key: Date.now(),
    }));

    // Update both files and images state
    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  function deleteImageProductImg(index) {
    fileInputRef.current.value = null;

    setFiles((prevFiles) => {
      return prevFiles.filter((_, i) => i !== index);
    });

    setImages((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });
  }
  function onDragOverProductImg(event) {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  }
  function onDragLeaveProductImg(event) {
    event.preventDefault();
    setIsDragging(false);
  }
  function onDrop(event) {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = event.dataTransfer.files;

    const imageFiles = Array.from(droppedFiles).filter((file) =>
      file.type.startsWith("image/")
    );

    const newImages = Array.from(imageFiles).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      key: Date.now(),
    }));

    // Update both files and images state
    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    setImages((prevImages) => [...prevImages, ...newImages]);
  }

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
          <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              {isLoading && (
                <HashLoader color="#F3C2C6" loading={true} size={100} />
              )}
            </div>

            <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <Box>
                        <Typography variant="h6" gutterBottom style={boldTypographyStyle}>
                            Blog Information
                        </Typography>

                        <TextField
                            label={`Title English ${renderAsterisk('title_en')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('title_en', e.target.value)}
                            error={!!validationErrors.title_en}
                            helperText={validationErrors.title_en}
                        />

                        <TextField
                            label={`Title Arabic ${renderAsterisk('title_ar')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('title_ar', e.target.value)}
                            error={!!validationErrors.title_ar}
                            helperText={validationErrors.title_ar}
                        />
                        <TextField
                            label={`Content English ${renderAsterisk('content_en')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('content_en', e.target.value)}
                            error={!!validationErrors.content_en}
                            helperText={validationErrors.content_en}
                        />

                        <TextField
                            label={`Content Arabic ${renderAsterisk('content_ar')}`}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => handleChange('content_ar', e.target.value)}
                            error={!!validationErrors.content_ar}
                            helperText={validationErrors.content_ar}
                        />
                    </Box>
                    <FormControl fullWidth margin="normal" variant="outlined" error={Boolean(validationErrors.hidden)}>
                        <InputLabel>Hidden or live</InputLabel>
                        <Select
                            value={blog.hidden || '1'}
                            onChange={(e) => handleChange('hidden', e.target.value)}
                            label="Select Is hidden or live"
                        >
                            <MenuItem value="1">Live</MenuItem>
                            <MenuItem value="2">Hidden</MenuItem>
                        </Select>

                        <FormHelperText variant="caption" error>
                            {validationErrors.hidden}
                        </FormHelperText>
                    </FormControl>

                    <div className="card">
            <div className="top">
              <p>Products Images</p>
            </div>
            <div
              className="drag-area"
              onDragOver={onDragOverProductImg}
              onDragLeave={onDragLeaveProductImg}
              onDrop={onDrop}
            >
              {isDragging ? (
                <span className="select">Drop Images here</span>
              ) : (
                <>
                  Drag & Drop here or{" "}
                  <span className="select" role="button" onClick={selectFiles}>
                    Browse
                  </span>
                </>
              )}
              <input
                name="file"
                type="file"
                className="file"
                multiple
                ref={fileInputRef}
                onChange={handleFileChange}
              ></input>
            </div>
            <div className="container">
              {images.map((image, index) => (
                <div className="image" key={index}>
                  <span
                    className="delete"
                    onClick={() => deleteImageProductImg(index)}
                  >
                    &times;
                  </span>
                  <img src={image.url} alt={image.name} />
                </div>
              ))}
            </div>
            </div>


                </DialogContent>
            </form>
            <DialogActions>
                <Button onClick={onClose} variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
                    Cancel
                </Button>
                <Button  onClick={handleSubmit} type="submit" variant="outlined" style={{ color: '#ffd700', ...boldTypographyStyle }}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddBlogDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AddBlogDialog;
